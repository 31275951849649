import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Panel from 'design/elements/Panel'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { useToast } from 'toast/ToastContext'
import { createInfluencerAccount } from 'Marketing/marketing.api.client'
import TextField from 'design/elements/TextField'

export default function Create() {
  const history = useHistory()
  const { notify, alert, clearAlert } = useToast()

  const [name, setName] = useState('')
  const [promo_code, setPromoCode] = useState('')
  const [description, setDescription] = useState('')

  const [submitting, setSubmitting] = React.useState(false)
  const mounted = React.useRef(true)

  const form = useForm({ mode: 'onBlur', reValidateMode: 'onChange' })

  const [mutate, { status }] = useMutation(createInfluencerAccount, {
    onSuccess(res) {
      notify(res)
    },
    onError(error) {
      alert(error)
    },
  })

  const handleSubmit = React.useCallback(() => {
    clearAlert()
    mutate({
      name,
      description,
      promo_code,
    })
  }, [clearAlert, mutate, name, description, promo_code])

  return (
    <Box width="70%">
      <Button onClick={() => history.goBack()} small variant="default">
        &larr; Back
      </Button>
      <Panel px="xl" py="md">
        <Text fontSize="sm" color="highlight" textTransform="uppercase" fontWeight="bold">
          Create Influencer
        </Text>
        <Spacer mt="sm" />
      </Panel>
      <Panel px="xl" py="md">
        <Box as="form" onSubmit={form.handleSubmit(handleSubmit)} width="50%" pt="md">
          <TextField
            value={name}
            label="Name"
            ref={form.register({ required: true })}
            name="name"
            py="sm"
            onChange={(e) => setName(e.target.value)}
            required
          />

          <TextField
            required
            value={promo_code}
            label="Promo Code"
            ref={form.register({ required: true })}
            name="promo_code"
            py="sm"
            onChange={(e) => setPromoCode(e.target.value)}
          />

          <TextField
            value={description}
            label="Description (Optional)"
            ref={form.register()}
            name="description"
            py="sm"
            onChange={(e) => setDescription(e.target.value)}
          />

          <Button variant="dark" type="submit" fullWidth>
            Create
          </Button>
        </Box>
      </Panel>
    </Box>
  )
}
