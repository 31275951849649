import * as React from 'react'
import styled from 'styled-components'
import SideBar from 'components/Sidebar'
import NavBar from 'components/NavBar'

const PageTemplateStyles = styled.div`
  display: flex;
  transition: all 0.9s ease-in-out;
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.background};

  & > header {
    width: 100%;
  }
  & > aside {
    width: 70px;
    padding-bottom: 40px;
  }
  & > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  main {
    padding: 2em 1em;
    overflow-x: hidden;
    min-height: 100vh;
  }
  &.sideBarOpen {
    & > aside {
      width: 200px;
    }
  }
`

const PageTemplate = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = React.useState(false)
  const toggleSideBar = () => {
    setSideBarOpen((open) => !open)
  }

  return (
    <>
      <PageTemplateStyles className={sideBarOpen ? 'sideBarOpen' : null}>
        <SideBar toggleSideBar={toggleSideBar} isOpen={sideBarOpen} />
        <section>
          <NavBar />
          <main>{children}</main>
        </section>
      </PageTemplateStyles>
    </>
  )
}

export default PageTemplate
