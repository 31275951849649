import * as React from 'react'
import styled from 'styled-components'
import Spacer from './Spacer'
import Box from './Box'

const Label = styled.label`
  font-family: inherit;
  font-size: ${(props) => (props.small ? '10px' : '12px')};
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #1c3244;
`
const Input = styled.input`
  border: 1px solid #dbe0e5;
  border-radius: ${(props) => (props.borderRadius !== undefined ? props.borderRadius : '0px')};
  background-color: ${({ disabled }) => (disabled ? '#E7EFF6' : '#ffffff')};
  font-family: inherit;
  height: ${(props) =>
    props.multiline ? 'auto' : props.small ? '25px' : props.large ? '55px' : props.height ? props.height : '40px'};
  font-size: ${(props) => (props.small ? '10px' : '12px')};
  width: 100%;
  padding: ${(props) => (props.multiline ? '1em' : '0em 0.5em 0em 0em')};
  text-indent: ${(props) => (props.multiline ? 0 : '1em')};
  color: #1c3244f2;
  ::placeholder {
    color: #8b9ba8;
    font-family: inherit;
  }
  resize: none;
  border-right-color: ${(props) => (props.noRightBorder ? 'transparent' : '#dbe0e5')};
  &[aria-invalid='true'] {
    border: 1px solid ${(props) => props.theme.colors.red};
  }
`

const PasswordIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 2.5em;
`

const Wrapper = styled.div`
  position: relative;
  & + span.form-helper-text {
    font-size: x-small;
    display: block;
    height: 20px;
    padding: 0.5em 0;
    transition: all 0.2s linear;
    position: absolute;
    color: red;
    &[aria-invalid='true'] + span.form-helper-text {
      color: red;
    }
  }
`

const getRandomId = () => `cf-input-${Math.ceil(Math.random() * 100)}-${Math.ceil(Math.random(100))}`
function TextField(props, ref) {
  const {
    label,
    type,
    name,
    disabled,
    defaultValue,
    value,
    required,
    error,
    onChange,
    helperText,
    multiline,
    id,
    placeholder,
    noRightBorder,
    borderRadius,
    inputProps = {},
    showPassword = false,
    height,
    ...otherProps
  } = props

  const [visible, updateVisible] = React.useState(false)
  const updateType = showPassword && visible ? 'text' : type

  return (
    <Box {...otherProps} position="relative">
      {label && (
        <>
          <Label htmlFor={id} small={otherProps.small}>
            {label}
            {required && '*'}
          </Label>
          <Spacer mt={otherProps.small ? '0.3em' : 'xs'} />
        </>
      )}
      {multiline ? (
        <Input
          error={error}
          multiline
          as="textarea"
          required={required}
          disabled={disabled}
          noRightBorder={noRightBorder}
          borderRadius={borderRadius}
          ref={ref}
          name={name}
          id={id}
          rows={4}
          aria-invalid={Boolean(error)}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          {...inputProps}
        ></Input>
      ) : (
        <Wrapper>
          <Input
            error={error}
            noRightBorder={noRightBorder}
            ref={ref}
            name={name}
            type={updateType}
            aria-invalid={Boolean(error)}
            required={required}
            disabled={disabled}
            id={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            height={height}
            borderRadius={borderRadius}
            {...inputProps}
          />
          {showPassword && type === 'password' ? (
            <PasswordIcon onClick={() => updateVisible((u) => !u)}>
              {visible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  width={25}
                  height={25}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  width={25}
                  height={25}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              )}
            </PasswordIcon>
          ) : null}
        </Wrapper>
      )}
      <span className="form-helper-text">{helperText}</span>
    </Box>
  )
}
export { Input, Label, getRandomId }
export default React.forwardRef(TextField)
