import styled from 'styled-components'
import { layout, space, color, typography, flexbox, border } from 'styled-system'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: transparent;
  td:last-of-type,
  th:last-of-type {
    border-right: solid 1px #e3ecf4;
  }
  ${layout};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  ${border};
`
export default Table
