import client from 'lib/client'
import makeForm from 'lib/makeFormData'
import { X_REQUEST_LOCK } from 'lib/config'

const headersConfig = {
  headers: {
    'X-REQUEST-LOCK': X_REQUEST_LOCK,
  },
}

export const fetchUnassignedTerminals = (query) =>
  client.get(`p/terminal/RetrieveListOfUnMapTerminals`, query, headersConfig)
export const assignTerminalToAgent = (body) => client(`p/terminal/MapTerminalsToAgent`, body)
export const unmapTerminal = (query) => client(`p/terminal/UnmapTerminal`, null, { method: 'DELETE', query })
export const fetchAgents = () => client.get(`p/terminal/TerminalAgentConfig`)
export const fetchChargeTypes = () => client.get(`p/terminal/ChargeTypeConfig`)
export const fetchHasCustomCharge = () => client.get(`p/terminal/HasCustomCharge`)
export const fetchPosHandlers = () => client.get(`i/agents/RetrieveAggregatorsAndFieldStaffOnly`)
export const fetchTerminals = (query) => client.get(`p/terminal/RetrieveTerminals`, query)
export const fetchTerminalRequests = (query) => client.get(`p/terminal/getAllTerminalRequest`, query)
export const fetchTerminalMetrices = (query) => client.get(`p/terminal/getTerminalRequestMetrix`, query)
export const fetchTerminalDetails = (query) => client.get(`p/terminal/TerminalDetails`, query)
export const fetchSponsorTerminalReports = (query) =>
  client.get(`p/sponsordashboard/GetSponsorTerminalReportByAgentId`, query)
export const fetchPOSFeeTypes = () => client.get(`p/sponsordashboard/GetAggregatorPOSFeeTypes`, null, headersConfig)
export const fetchPOSSearchByOptions = () =>
  client.get('p/sponsordashboard/getSponsorTerminalReportSearchBy', null, headersConfig)
export const fetchTerminalAssignmentHistory = (query) => client.get(`p/terminal/RetrieveAgentHistoryForTerminal`, query)
export const deleteTerminal = (query) =>
  client(`p/terminal/DeleteTerminal?terminalid=${query.terminalid}`, null, { method: 'DELETE' })
export const registerTerminal = (body) => client(`p/terminal/RegisterTerminal`, body)
export const updateTerminalRequestStatus = (body) =>
  client(`p/terminal/UpdateTerminalRequestStatus`, body, {
    method: 'PATCH',
  })
export const updateTerminal = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/updateTerminal`, formData, {
    method: 'PATCH',
  })
}
export const updatePricingCategory = (body) => {
  return client(`p/terminal/UpdateTerminalPricingCategory`, body, {
    method: 'PATCH',
  })
}

export const updateTerminalWithdrawalLimit = (body) => {
  return client(`p/terminal/UpdateTerminalWithdrawalLimit`, body, {
    method: 'PUT',
  })
}

export const sponsorTerminalRefund = (body) => {
  return client(`t/sponsorportal/sponsor-terminal-refund`, body)
}

export const bulkUploadTerminals = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalBulkUpload`, formData)
}

export const bulkUpdateTerminals = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalBulkUpdate`, formData, { method: 'PATCH' })
}
export const bulkUnmapTerminals = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalBulkUnMap`, formData)
}
export const bulkDeleteTerminals = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalBulkDelete`, formData)
}

export const mapTerminalsToSponsors = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalToSponsorMappingBulkUpload`, formData)
}
export const unmapTerminalsFromSponsors = (body) => {
  const formData = makeForm(body)
  return client(`p/terminal/TerminalBulkUnMapFromSponsor`, formData)
}

export const fetchTerminalConfigurationDetails = (terminalId) =>
  client.get(`p/sponsordashboard/GetTerminalConfigurationDetails?terminalid=${terminalId}`)
export const updateTerminalConfigurationDetails = (queryString) =>
  client.put(`p/sponsordashboard/UpdateTerminalConfigurationDetailsAndNotifyTerminal?${queryString}`)

// https://gateforce-pos-dev.azurewebsites.net/api/v1/terminal/getAllTerminalRequest?pageIndex=1&pageSize=10
