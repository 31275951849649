import React from 'react'
import ReactDOM from 'react-dom'
import Text from 'design/elements/Text'
import Box from 'design/elements/Box'
import styled from 'styled-components'

const ROOT_ID = 'toast-root'
const getToastRoot = () => {
  let root = document.getElementById(ROOT_ID)
  if (!root) {
    root = document.createElement('div')
    root.id = ROOT_ID
    document.body.appendChild(root)
  }
  return root
}

export default function Toast({ messages, remove }) {
  return ReactDOM.createPortal(
    <NotificationRoot>
      {messages.map(({ type, message, title, id }) => (
        <StyledAlert key={id} py="lg" pl="xl" my="md" className={type}>
          <button onClick={() => remove(id)} className="close">
            <svg
              width="20"
              height="20"
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="title"
              aria-describedby="desc"
              role="img"
            >
              <path
                d="M51 17.3L46.8 13 32 27.8 17.3 13 13 17.3 27.8 32 13 46.8l4.3 4.2L32 36.3 46.8 51l4.2-4.2L36.3 32 51 17.3z"
                strokeWidth="1"
                strokeMiterlimit="10"
                strokeLinecap="round"
                data-name="layer1"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <Box width="80%">
            <Text fontWeight="bold" lineHeight={1.2} fontSize="sm">
              {title || 'Notification'}
            </Text>
          </Box>
          <Text mt="xl" fontSize="x-small" pr="md">
            {message.replace(/"/g, '').replace(/\|/g, '\n')}
          </Text>
        </StyledAlert>
      ))}
    </NotificationRoot>,
    getToastRoot()
  )
}
const StyledAlert = styled(Box)`
  pointer-events: all;
  width: 20em;
  position: relative;
  max-width: 320px;
  height: 120px;
  overflow: hidden;
  z-index: inherit;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.19);

  .close {
    color: inherit;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    will-change: border-color, background-color;
    background-color: transparent;
    transition: border-color 0.2s linear, background-color 0.1s linear;

    :hover {
      background-color: #ffffff63;
    }

    :focus {
      outline: none;
      border-color: currentColor;
    }
  }

  svg {
    vertical-align: middle;
    stroke: currentColor;
    fill: currentColor;
  }

  &:before {
    display: block;
    height: 121px;
    width: 10px;
    position: absolute;
    left: -2px;
    top: -1px;
    content: ' ';
  }

  &.success {
    border-color: #a6d8bed4;
    background-color: #d2f0e1;
    color: #347f5e;

    svg {
      stroke: #67c7a3;
    }

    :before {
      background: #67c7a3;
    }
  }

  &,
  &.info {
    border-color: #3ca1ca;
    background-color: #b4dff0;
    color: #1979a0;

    svg {
      stroke: #4495b6;
    }

    :before {
      background: #4495b6;
    }
  }

  &.warning {
    background-color: #f6d996;
    border-color: #ffca5d;
    color: #876326;

    svg {
      stroke: #dcb86df7;
    }

    :before {
      background: #dcb86df7;
    }
  }

  &.error {
    background-color: #ffbebe;
    border-color: #d95858;
    color: #8d4444;

    svg {
      stroke: #d95858;
    }

    :before {
      background: #d95858;
    }
  }
`
const NotificationRoot = styled(Box)`
  position: fixed;
  z-index: 999999;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 35px 20px;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
