import { Redirect, Route, Switch } from 'react-router-dom'

import Box from 'design/elements/Box'
import TabLink from 'design/elements/TabLink'
import withTitle from 'lib/withTitle'

import MarketingIndex from './screens/Index'
import Create from './screens/Create'

export default withTitle('Marketing', MarketingModule)

function MarketingModule() {
  return (
    <Box maxWidth="100vw" overflowX="hidden">
      <Tabs />
      <Box mt="xl">
        <Switch>
          <Route path="/marketing/all">
            <MarketingIndex />
          </Route>
          <Route path="/marketing/create">
            <Create />
          </Route>
          <Redirect to="/marketing/all" />
        </Switch>
      </Box>
    </Box>
  )
}

function Tabs() {
  const tabs = [
    { url: '/marketing/all', text: 'Marketing' },
    { url: '/marketing/create', text: 'Create New Influencer' },
  ]
  return (
    <Box>
      {tabs.map((tab) => (
        <TabLink key={tab.url} mr="md" to={tab.url}>
          {tab.text}
        </TabLink>
      ))}
    </Box>
  )
}
