import React from 'react'
import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Text from 'design/elements/Text'
import Spacer from 'design/elements/Spacer'
const __DEV__ = process.env.NODE_ENV !== 'production'

export default function Error({ error, componentStack, resetErrorBoundary }) {
  return (
    <Box width="100%" height="100vh" display="grid" gridTemplateRows="30% 1fr" gridTemplateColumns="100%">
      <Box color="white" bg="blue.2" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Text as="h1" fontSize="xl">
          Oops! Something went wrong
        </Text>
        <Spacer mt="lg" />
        <Button variant="blue" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </Box>
      <Box bg="background" px="xl" py="md">
        {__DEV__ && (
          <>
            <Text color="highlight" as="pre" fontSize="md">
              {error.message}
            </Text>
            <pre>{componentStack}</pre>
          </>
        )}
      </Box>
    </Box>
  )
}
