import styled from 'styled-components'
import { space, layout, position } from 'styled-system'

const Spacer = styled.div`
  z-index: 2;
  box-shadow: ${(props) => (props.shadow ? '0px 4px 18px 2px rgba(0,0,0,0.2);' : 'none')};
  ${space};
  ${layout};
  ${position};
`

export default Spacer
