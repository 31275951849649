import styled from 'styled-components'
import { layout, space, color, typography, flexbox, border } from 'styled-system'

const TD = styled.td`
  padding: 2em 0.7em;
  font-size: 12px;
  text-align: unset;
  vertical-align: middle;
  ${layout};
  ${space};
  ${color};
  ${typography};
  ${border};
  ${flexbox};
`
export default TD
