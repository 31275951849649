import Text from './Text'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import React from 'react'
import { typography } from 'styled-system'

const FieldTextStyles = styled(Text)`
  font-size: ${(props) => (props.isLabel ? '12px' : props.isValue ? '14px' : 'inherit')};
  font-weight: ${(props) => (props.isLabel ? 'bold' : props.isValue ? 'bold' : 'normal')};
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.isValue ? props.theme.colors.highlight : 'inherit'};
  ${typography};
  text-align: ${(props) => props.textAlign || 'inherit'};
`
export default function FieldText({ children, loading, ...props }) {
  if (loading) {
    return (
      <FieldTextStyles {...props}>
        <Skeleton height={13} duration={3} />
      </FieldTextStyles>
    )
  }
  return <FieldTextStyles {...props}>{children === null ? '-' : String(children)}</FieldTextStyles>
}
