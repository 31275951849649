import React from 'react'
import styled from 'styled-components'
import { ButtonLoader } from 'design/loaders'
import { variant, color, space, layout } from 'styled-system'
import { buttonVariants } from '../theme'

const ButtonStyles = styled('button')(
  (props) => ({
    padding: props.round ? '0.7em' : props.small ? '0.4em 0.9em' : '0.8em 1em',
    opacity: props.disabled ? '0.6' : '1',
    border: 'solid 1px #e3ecf4',
    backgroundColor: '#ffffff',
    cursor: props.loading || props.disabled ? 'none' : 'pointer',
    appearance: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: props.small ? '10px' : '14px',
    minWidth: props.fullWidth ? '100%' : 'initial',
    fontFamily: 'inherit',
    fontWeight: 500,
    height: props.round ? props.size || '15px' : props.small ? '25px' : '40px',
    width: props.round ? props.size || '15px' : 'auto',
    borderRadius: props.borderRadius !== undefined ? props.borderRadius : 0,
    borderColor: props.transparentBorder ? 'transparent !important' : '#e3ecf4',
    pointerEvents: props.loading || props.disabled ? 'none' : 'initial',
  }),
  variant({
    variants: buttonVariants,
  }),
  color,
  space,
  layout
)

ButtonStyles.displayName = `Button`

function Button({ loading, children, loader, ...props }) {
  return (
    <ButtonStyles disabled={loading} {...props}>
      {loading ? loader || <ButtonLoader small={props.small} /> : children}
    </ButtonStyles>
  )
}

export default Button
