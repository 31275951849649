import styled from 'styled-components'
import { layout, space, color, typography, flexbox, background, opacity } from 'styled-system'

const TR = styled.tr`
  border: solid 1px #e3ecf4;
  background-color: white;
  ${layout};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  transition: all 0.2s linear;
  background: ${(props) => (props.selected ? '#ebeaec05' : 'white')};
  &:hover {
    background: ${(props) => (props.selectable ? '#ebeaec05' : 'white')};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'initial')};
  }
  ${background};
  ${opacity}
`
export default TR
