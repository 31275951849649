import React from 'react'
import styled from 'styled-components'
import UserPlaceHolderImage from 'design/assets/images/user-placeholder.jpg'
import { Link } from 'react-router-dom'
import { logout } from 'lib/client'
import { APP_VERSION } from 'lib/config'
import { useAuthState } from '../auth/context/Auth.Context'
import { capitalize } from '../lib/formatters'

const NavBarStyles = styled.header`
  height: 70px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e3ecf4;
  background-color: white;
  .material-icons-touchable {
    cursor: pointer;
  }

  .box {
    width: 70%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 16px 0 26px;

    .material-icons {
      width: (17.5px);
      height: (17.5px);
      color: #8b9ba8;
      margin-right: (7.5px);
    }

    & a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .notify-bell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-left: solid 1px #e3ecf4;
    border-right: solid 1px #e3ecf4;
    position: relative;
  }

  .navbar-avatar-section {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 1em;
    & img {
      width: 36px;
      height: 36px;
      object-fit: fill;
      border-radius: 3px;
      margin-right: 13px;
      margin-left: 17px;
    }
    & a {
      text-decoration: none;
      color: #1c3244;
    }
    cursor: pointer;

    img:hover + .navbar-avatar-dropdown {
      display: block;
    }
  }
`

const NavBar = () => {
  const { user } = useAuthState()
  return (
    <NavBarStyles>
      <div className="box">
        <Link to="/">
          <h2 title={`Role: ${user.user_role}`}>{capitalize(user.agent_username)} </h2>
        </Link>
      </div>

      <div className="navbar-avatar-section">
        <img src={UserPlaceHolderImage} alt="" />
        <button onClick={logout} style={{ cursor: 'pointer' }}>
          Logout
        </button>{' '}
        &nbsp; | &nbsp;<small>v{APP_VERSION}</small>
      </div>
    </NavBarStyles>
  )
}

export default NavBar
