import React from 'react'

import Box from 'design/elements/Box'
import Select from 'design/elements/Select'
import TextField from 'design/elements/TextField'
import Button from 'design/elements/Button'
import Text from 'design/elements/Text'
import Panel from 'design/elements/Panel'
import FullTable from 'design/elements/FullTable'
import TR from 'design/elements/TR'
import TD from 'design/elements/TD'
import TH from 'design/elements/TH'
import { useTransactionUtils } from 'lib/App.Provider'

export default function InfluencersListTable({ loading, influencers, setSelectedInfluencer, selectedInfluencer }) {
  const { data } = influencers
  const utils = useTransactionUtils()

  const getRowProps = React.useCallback(
    (row) => {
      return {
        select() {
          setSelectedInfluencer(row)
        },
        selected: setSelectedInfluencer?.id === row.id,
        getTypeByValue: utils.byValue,
      }
    },
    [selectedInfluencer, setSelectedInfluencer, utils]
  )

  return (
    <FullTable
      selectBy="id"
      elements={data?.result || []}
      loading={loading && !data?.result?.length}
      RenderRow={RenderRow}
      RenderHeaders={RenderHeaders}
      getRowProps={getRowProps}
    />
  )
}

const RenderRow = ({ row: influencer, triggerMenu, rowProps }) => {
  return (
    <TR selected={rowProps.selected} onClick={rowProps.select} selectable>
      <TD>{influencer.name}</TD>
      <TD>{influencer.promo_code}</TD>
      <TD>{influencer.description}</TD>
    </TR>
  )
}

const RenderHeaders = () => (
  <tr>
    <TH>Influencer Name</TH>
    <TH>Promo Code</TH>
    <TH textAlign="center">Description</TH>
    <TH />
  </tr>
)
