import React from 'react'
import Title from 'design/Title'

export default function withTitle(title, Component) {
  return function (props) {
    return (
      <>
        <Title>{title}</Title>
        <Component {...props} />
      </>
    )
  }
}
