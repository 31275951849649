import Axios from 'axios'
import { baseUrl, baseUrl2, baseUrl3 } from 'lib/config'
import { getToken } from './client'

export const axios = Axios.create({
  baseURL: baseUrl,
  headers: {
    'App-Id': process.env.REACT_APP_APP_ID,
  },
})
export const axios2 = Axios.create({
  baseURL: baseUrl2,
  headers: {
    'App-Id': process.env.REACT_APP_APP_ID,
  },
})

axios.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})
axios2.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})
axios.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    // Object.assign(config.headers, config.data.getHeaders())
  }
  return config
})
export const pickErrorKey =
  (key = 'error_message') =>
  (error) => {
    if (error.response) {
      if (!key) {
        return Promise.reject(error.response.data)
      }
      return Promise.reject(error.response.data?.[key] ?? error.response.data)
    } else if (error.request) {
      throw new Error(`Unexpected request error`)
    } else {
      throw new Error(`Client error`)
    }
  }
export const pickResponseKey =
  (key = 'result') =>
  (response) => {
    if (!key) {
      return response.data
    }
    return response.data[key]
  }
export const pickResult = pickResponseKey('result')
export const pickErrorMessage = pickErrorKey('error_message')
