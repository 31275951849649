import client from 'lib/client'
import axios from 'axios'

export const login = (body) => client(`i/account/login`, body)
export const requestPasswordReset = (body) => client('i/account/reset-password', body)
export const validateOTP = (body) => client('i/mobile/agent/validate-otp', body)
export const changePassword = (body) => client(`i/account/change-password`, body, { method: 'PATCH' })

export const confirmReset = (body) => client(`i/mobile/agent/confirm-reset`, body)
export const getLocation = () => axios.get(`https://api64.ipify.org?format=json`)

// +++++++++++++++++ FUND TRANSFER +++++++++++++++++++++++++++++++++++
export const getAccountOptions = () => client.get(`b/bank/GetFundTransferList`)
export const TransferFunds = (body) => client(`t/transaction/FundTransfer`, body)
