import { snakeCase } from 'lodash'
import capitalize from 'lodash.capitalize'
import always from 'ramda/es/always'
import curry from 'ramda/es/curry'
import compose from 'ramda/es/compose'
import cond from 'ramda/es/cond'
import equals from 'ramda/es/equals'
import identity from 'ramda/es/identity'
import join from 'ramda/es/join'
import map from 'ramda/es/map'
import split from 'ramda/es/split'
import T from 'ramda/es/T'

export { default as debounce } from 'lodash.debounce'
export { default as capitalize } from 'lodash.capitalize'
export const naira = (amount) =>
  Number.isFinite(+amount)
    ? new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(+amount)
    : '-'
export function cleanTerminalID(stringContainingTerminalId) {
  return stringContainingTerminalId.replace(/-(\d)+CRF?/, (...args) => {
    return [args[1], 'CRF'].join('')
  })
}
export function transformTransferOptionName(option) {
  const transform = compose(
    join(' '),
    map(
      cond([
        [equals('to'), identity],
        [equals('gt'), always('GT')],
        [T, capitalize],
      ])
    ),
    split('_'),
    snakeCase
  )
  return transform(option)
}

export const getTextColorForStatus = (status = '') => {
  switch (status?.toLowerCase()) {
    case 'successful':
    case 'successfull':
    case 'active':
    case 'transacting':
      return 'green'

    case 'unsuccessful':
    case 'failed':
    case 'inactive':
    case 'damaged':
    case 'lost':
      return 'red'

    case 'pending':
    case 'on repair':
      return 'brown'
    default:
      return 'default'
  }
}

export const loading = (status) => ['loading'].includes(status)

export function matchAgainstConfig(config, value) {
  return config.find((item) => +item.value === +value)
}

export function formatDateTime(arg, defaultToToday) {
  if (!defaultToToday && !arg) {
    return '-'
  }

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true,
  }

  const [date, time] = new Intl.DateTimeFormat('en-NG', options).format(new Date(arg || Date.now())).split(',')
  return [date, time]
    .map((s) => s.trim())
    .join(' ')
    .toUpperCase()
}

export function formatDate(arg, defaultToToday = true) {
  if (!defaultToToday && !arg) {
    return '-'
  }
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }

  const [date] = new Intl.DateTimeFormat('en-NG', options).format(new Date(arg || Date.now())).split(',')
  let [d, m, y] = date.split('/')
  return [m, d, y].join('/')
}

export const formatDateAsDayMonth = (arg, defaultToToday = true) => {
  if (!defaultToToday && !arg) {
    return '-'
  }
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }

  const [date] = new Intl.DateTimeFormat('en-NG', options).format(new Date(arg || Date.now())).split(',')
  let [d, m, y] = date.split('/')
  return [d, m, y].join('/')
}
export function dateAsMonthDay(arg, defaultToToday = true) {
  if (!defaultToToday && !arg) {
    return '-'
  }
  const options = {
    month: 'short',
    day: 'numeric',
  }

  const [d, m] = new Intl.DateTimeFormat('en-NG', options).format(new Date(arg || Date.now())).split(' ')
  return [m, d].join(' ')
}

export function dateAsMDY(dateString) {
  const date = new Date(dateString)
  return [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('/')
}

export const pad = (maxLength, fillString, value) => String(value).padStart(maxLength, fillString)

export function dateAsYMD(dateString) {
  const date = new Date(dateString)
  return [date.getFullYear(), pad(2, '0', date.getMonth() + 1), pad(2, '0', date.getDate())].join('/')
}

export const percentageColor = (value) => {
  return value >= 50 ? 'green' : value >= 40 ? 'yellow' : 'red'
}

export function toNativeDate(formatted) {
  return new Date(formatted)
}

export function shouldLoadForData(status, data) {
  let hasData = !!data && typeof data === 'object' ? Object.keys(data.length) > 0 : false
  return loading(status) && !hasData
}
export function percentage(val, total, dp = 0) {
  return ((val / total) * 100).toFixed(dp)
}
export function sum(...values) {
  return values.flat(Infinity).reduce((a, b) => a + b, 0)
}
const ONE_DAY_MS = 1000 * 60 * 60 * 24

export function xDaysAgo(days = 1) {
  return Date.now() - days * ONE_DAY_MS
}

export function xDaysFromNow(days = 1) {
  return xDaysAgo(-1 * days)
}

const AGENT_STATUS_ENUM = {
  0: 'Active',
  1: 'Inactive',
  2: 'Transacting',
  3: 'Dormant',
  4: 'Deactivated',
  5: 'Rejected',
  6: 'Lien',
  7: 'Frozen',
}

export function matchAgentStatus(value) {
  let status = AGENT_STATUS_ENUM[+value]
  if (!status) {
    return `Invalid status ${value}, expected on of ${Object.keys(AGENT_STATUS_ENUM).join(', ')}`
  }
  return status
}

export const pascalCase = (word) => word.split('_').map(capitalize).filter(Boolean).join('')

export function paschalKeys(obj) {
  const result = {}
  for (let [key, value] of Object.entries(obj)) {
    result[pascalCase(key)] = value
  }
  return result
}

function makeNumeric(keys, obj) {
  let res = {}
  for (let [key, value] of Object.entries(obj)) {
    res[key] = keys.includes(key) ? Number(String(value).replace(/,/g, '')) : value
  }
  return res
}

export const makeValuesNumeric = curry(makeNumeric)

export function wait() {
  return new Promise((res, timeout = 1000) => {
    setTimeout(res, timeout)
  })
}

export const getRand = (range = 1000) => Math.ceil(Math.random() * range)

export const parseKoboAndFormat = (amount) => {
  const toNaira = amount / 100
  return toNaira.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getTMSTransactionCommentAndColor = (code) => {
  let color
  let comment

  switch (code) {
    case '00':
      color = 'success'
      comment = 'Approved'
      break

    case '09':
      color = 'warning'
      comment = 'Pending'
      break

    default:
      color = 'error'
      comment = 'Declined'
      break
  }

  return [comment, color]
}

export const transMgs = {
  '00': 'Approved',
  '01': 'Refer to card issuer',
  '02': 'Refer to card issuer, special condition',
  '03': 'Invalid merchant',
  '04': 'Pick-up card',
  '05': 'Do not honor',
  '06': 'Error',
  '07': 'Pick-up card, special condition',
  '08': 'Honor with identification',
  '09': 'Request in progress',
  10: 'Approved, partial',
  11: 'Approved, VIP',
  12: 'Invalid transaction',
  13: 'Invalid amount',
  14: 'Invalid card number',
  15: 'No such issuer',
  16: 'Approved, update track 3',
  17: 'Customer cancellation',
  18: 'Customer dispute',
  19: 'Re-enter transaction',
  20: 'Invalid response',
  21: 'No action taken',
  22: 'Suspected malfunction',
  23: 'Unacceptable transaction fee',
  24: 'File update not supported',
  25: 'Unable to locate record',
  26: 'Duplicate record',
  27: 'File update edit error',
  28: 'File update file locked',
  29: 'File update failed',
  30: 'Format error',
  31: 'Bank not supported',
  32: 'Completed partially',
  33: 'Expired card, pick-up',
  34: 'Suspected fraud, pick-up',
  35: 'Contact acquirer, pick-up',
  36: 'Restricted card, pick-up',
  37: 'Call acquirer security, pick-up',
  38: 'PIN tries exceeded, pick-up',
  39: 'No credit account',
  40: 'Function not supported',
  41: 'Lost card',
  42: 'No universal account',
  43: 'Stolen card',
  44: 'No investment account',
  51: 'Not sufficient funds',
  52: 'No check account',
  53: 'No savings account',
  54: 'Expired card',
  55: 'Incorrect PIN',
  56: 'No card record',
  57: 'Transaction not permitted to cardholder',
  58: 'Transaction not permitted on terminal',
  59: 'Suspected fraud',
  60: 'Contact acquirer',
  61: 'Exceeds withdrawal limit',
  62: 'Restricted card',
  63: 'Security violation',
  64: 'Original amount incorrect',
  65: 'Exceeds withdrawal frequency',
  66: 'Call acquirer security',
  67: 'Hard capture',
  68: 'Response received too late',
  75: 'PIN tries exceeded',
  77: 'Intervene, bank approval required',
  78: 'Intervene, bank approval required for partial amount',
  90: 'Cut-off in progress',
  91: 'Issuer or switch inoperative',
  92: 'Routing error',
  93: 'Violation of law',
  94: 'Duplicate transaction',
  95: 'Reconcile error',
  96: 'System malfunction',
  98: 'Exceeds cash limit',
}
export const transMgsOptions = Object.entries(transMgs).map(([code, message]) => ({
  value: code,
  label: `${code}-${message}`,
}))
export const getResponseMsg = (code) => {
  return transMgs[code] || 'No response message for this code'
}

export const INTERCHANGES = {
  1: 'Interswitch',
  27: 'ITEX(NIBSS)',
  32: 'NIBSS (Xpresspay)',
  213569: 'UP',
}

export const OPERATOR_RULES = {
  LESS_THAN: 'LESS_THAN',
  GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
  STARTS_WITH: 'STARTS_WITH',
}
export const interchangeFormat = (id) => {
  return INTERCHANGES[id] ?? 'UNKNOWN'
}

export const getCardScheme = (masked_pan) => {
  // check to ensure its notamount
  if (typeof masked_pan === 'number') {
    if (masked_pan > 100) {
      return masked_pan / 100
    } else {
      masked_pan = String(masked_pan)
    }
  }
  let schemeNo = masked_pan?.substring(0, 2)
  let scheme
  if (/[4]/.test(schemeNo)) {
    scheme = 'VISA'
  } else if (/[5][1-5]/.test(schemeNo)) {
    scheme = 'MASTERCARD'
  } else if (/[5][0]/.test(schemeNo)) {
    scheme = 'VERVE'
  } else {
    scheme = 'UNKNOWN'
  }
  return scheme
}

export const getDATColor = (val, DATT) => (val < DATT ? 'red' : val === DATT ? 'yellow' : 'green')
export const getDATVariant = (val, DATT) => (val < DATT ? 'error' : val === DATT ? 'warning' : 'success')
const thresholds = {
  GOOD: 6,
  ISSUE: 15,
}
export const getDurationColorCode = (duration) => {
  if (duration <= thresholds.GOOD) {
    return 'success'
  }
  if (thresholds.GOOD < duration && duration <= thresholds.ISSUE) {
    return 'warning'
  }
  return 'error'
}

export const getPercentageColorScheme = (value) => {
  if (value > 50) {
    return 'success'
  }
  if (value > 40) {
    return 'warning'
  }
  return 'error'
}

export const toDays = (num) => `${num} day${Number(num) !== 1 ? 's' : ''}`
