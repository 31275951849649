import React from 'react'
import { useQuery } from 'react-query'
import { fetchAgents, fetchChargeTypes, fetchHasCustomCharge, fetchPosHandlers } from 'terminals/terminals.api.client'
import { terminalStatus } from 'lib/config'

const TerminalsConfigContext = React.createContext({
  agents: [],
  chargeTypes: [],
  hasCustomCharge: [],
  posHandlers: [],
})

function TerminalsConfigProvider({ children }) {
  const { data: agents } = useQuery('terminals-config-agents', fetchAgents, {
    staleTime: Infinity,
  })
  const { data: chargeTypes } = useQuery('terminals-config-chargeTypes', fetchChargeTypes, {
    staleTime: Infinity,
  })

  const { data: hasCustomCharge } = useQuery('terminals-config-hasCustomCharge', fetchHasCustomCharge, {
    staleTime: Infinity,
  })
  const { data: posHandlers } = useQuery('terminals-config-posHandlers', fetchPosHandlers, {
    staleTime: Infinity,
  })

  const value = React.useMemo(() => {
    return {
      agents,
      chargeTypes,
      hasCustomCharge,
      posHandlers,
    }
  }, [agents, chargeTypes, hasCustomCharge, posHandlers])

  return <TerminalsConfigContext.Provider value={value}>{children}</TerminalsConfigContext.Provider>
}

function useAgents() {
  const { agents = [] } = React.useContext(TerminalsConfigContext)
  return agents
}

function useChargeTypes() {
  const { chargeTypes = [] } = React.useContext(TerminalsConfigContext)
  return chargeTypes.map((t) => ({ label: t, value: t }))
}

function useHasCustomCharge() {
  const { hasCustomCharge = [] } = React.useContext(TerminalsConfigContext)
  return hasCustomCharge.map((t) => ({ label: t, value: t }))
}
function usePosHandlers() {
  const { posHandlers = [] } = React.useContext(TerminalsConfigContext)
  return posHandlers
}

const _terminalStatuses = Object.entries(terminalStatus).map(([value, label]) => ({
  value: +value,
  label,
}))
function useTerminalStatus() {
  return _terminalStatuses
}

export { TerminalsConfigProvider, useAgents, useHasCustomCharge, useChargeTypes, usePosHandlers, useTerminalStatus }
