import React from 'react'
import Box from 'design/elements/Box'
import { InputLoader } from 'design/loaders'

export default function PageLoader() {
  return (
    <Box height="90vh" display="flex" justifyContent="center" alignItems="center">
      <InputLoader size={10} />
    </Box>
  )
}
