import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { layout, color, space } from 'styled-system'
const ModalStyle = styled(motion.div)`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 3em;
  display: grid;
  place-items: center;

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
    background-color: #0f375a;
  }
  [role='dialog'] {
    position: relative;
    outline: none;
    background: transparent;
    padding: 0.2em;
    max-height: 100%;
    overflow: scroll;
  }
  ${color};
  ${layout};
  ${space};
`

const ESC_KEY = 27
export default function Modal({ children, close }) {
  const modalContentRef = React.useRef()
  const lastFocusedEl = React.useRef()
  React.useEffect(() => {
    let ps
    const rec = modalContentRef.current?.getBoundingClientRect()
    const { height: documentHeight } = document.documentElement.getBoundingClientRect()
    let heightRatio = Number((rec.height / documentHeight).toFixed(1))
    if (heightRatio < 0.6) {
      return
    }

    import('perfect-scrollbar').then((module) => {
      /* eslint-disable */
      ps = new module.default(modalContentRef.current, { onScroll: () => {} })
      /* eslint-enable */
    })

    document.body.style.overflow = 'auto'
    document.body.style.height = '100vh'

    return () => {
      if (ps) {
        ps.destroy()
      }
      document.body.style.overflow = 'initial'
      document.body.style.height = 'auto'
    }
  }, [])

  React.useEffect(() => {
    lastFocusedEl.current = document.activeElement
    modalContentRef.current.focus()

    return () => {
      lastFocusedEl.current.focus()
    }
  }, [modalContentRef, lastFocusedEl])

  React.useEffect(() => {
    function handleKeyDown(e) {
      switch (e.keyCode) {
        case ESC_KEY:
          close()
          break

        default:
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [close])
  return (
    <AnimatePresence>
      <ModalStyle key="modal" animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        <div className="overlay" onClick={close} tabIndex={-1} />
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: -10 }}
          tabIndex={0}
          role="dialog"
          ref={modalContentRef}
        >
          {children}
        </motion.div>
      </ModalStyle>
    </AnimatePresence>
  )
}
