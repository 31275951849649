import React from 'react'
import { fetchAgentStatuses, fetchAgentTypes, fetchAgentCategories } from 'agents/agents.api.client'
import { useQuery } from 'react-query'
import capitalize from 'lodash.capitalize'
import snakeCase from 'lodash.snakecase'
import compose from 'ramda/es/compose'
import split from 'ramda/es/split'
import map from 'ramda/es/map'
import join from 'ramda/es/join'
import useCampaigns from 'lib/hooks/useCampaigns'

const AgentConfigContext = React.createContext({
  statusValues: [],
  agentTypes: [],
  banks: [],
  campaigns: [],
  agentCategories: [],
})

function AgentConfigProvider({ children }) {
  const { data: statusValues } = useQuery('config-statusValues', fetchAgentStatuses, { staleTime: Infinity })
  const { data: agentTypes } = useQuery('config-agentTypes', fetchAgentTypes, {
    staleTime: Infinity,
  })
  const { data: agentCategories = [] } = useQuery('config-agentCategories', fetchAgentCategories, {
    staleTime: Infinity,
  })
  const [banks] = React.useState([])

  const value = React.useMemo(() => {
    return {
      statusValues,
      agentTypes,
      banks,
      agentCategories,
    }
  }, [statusValues, agentTypes, banks, agentCategories])

  return <AgentConfigContext.Provider value={value}>{children}</AgentConfigContext.Provider>
}

const transformStatus = (status) => ({
  ...status,
  name: capitalize(status.name),
})

function useAgentStatuses(exemptList = []) {
  const { statusValues = [] } = React.useContext(AgentConfigContext)
  const checkExemptions = exemptList.length > 0
  if (!checkExemptions) {
    return statusValues.map(transformStatus)
  }

  return statusValues.reduce((acc, status) => {
    if (!exemptList.includes(status.name.toLowerCase())) {
      return acc.concat(transformStatus(status))
    }
    return acc
  }, [])
}

function useBanks() {
  const { banks } = React.useContext(AgentConfigContext)
  return banks
}

const transformAgentType = (type) => ({
  ...type,
  name: compose(join(' '), map(capitalize), split('_'), snakeCase)(type.name),
})

function useAgentTypes(allowList = []) {
  const { agentTypes = [] } = React.useContext(AgentConfigContext)
  const All = [{ value: null, name: 'All Agents' }]
  const checkFilter = allowList.length > 0
  if (!checkFilter) {
    return All.concat(agentTypes.map(transformAgentType))
  }
  return All.concat(agentTypes).reduce((acc, type) => {
    if (allowList.includes(type.name.toLowerCase())) {
      return acc.concat(transformAgentType(type))
    }
    return acc
  }, [])
}

function useAgentCategories() {
  const { agentCategories = [] } = React.useContext(AgentConfigContext)
  return [{ value: null, name: 'All Agents' }].concat(agentCategories)
}

export { AgentConfigProvider, useAgentStatuses, useBanks, useCampaigns, useAgentTypes, useAgentCategories }
