import * as React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import { Reset } from 'styled-reset'
import GlobalStyles from './GlobalStyles'
import { HelmetProvider } from 'react-helmet-async'

export function ForceProvider({ children }) {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Reset />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </HelmetProvider>
  )
}
