import React from 'react'
import * as ReactIs from 'react-is'
import { useRoleMatch } from './GateContext'

function _HideTree({ children, forRoles, cond }) {
  const match = useRoleMatch()

  if (!!cond) {
    return cond() ? null : children
  }

  if (match(forRoles)) {
    return null
  }
  return children
}

function _ShowTree({ children, forRoles, cond }) {
  const match = useRoleMatch()

  if (typeof cond === 'function') {
    return cond() ? children : null
  }

  if (match(forRoles)) {
    return children
  }

  return null
}

function _DisableTree({
  children,
  forRoles,
  fadeTo = 0.7,
  cond,
  message = 'You may not have permission to use this feature, please contact the Operations Manager',
}) {
  const match = useRoleMatch()

  if (ReactIs.isFragment(children)) {
    console.error(`GATE: DisableTree does not allow fragments as children, consider using a wrapping div`)
  }

  if (!!cond) {
    return cond()
      ? React.Children.map(children, (child) =>
          React.cloneElement(child, { style: { disabled: true, opacity: fadeTo ?? 1, pointerEvents: 'none' } })
        )
      : children
  }

  if (match(forRoles)) {
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        style: { disabled: true, opacity: fadeTo ?? 1, pointerEvents: 'none' },
        title: message,
      })
    )
  }
  return children
}

function _EnableTree({ children, forRoles, cond }) {
  const match = useRoleMatch()

  if (ReactIs.isFragment(children)) {
    console.error(`GATE: EnableTree does not allow fragments as children, consider using a wrapping div`)
  }
  if (!!cond) {
    return cond()
      ? children
      : React.Children.map(children, (child) =>
          React.cloneElement(child, { style: { disabled: true, pointerEvents: 'none' } })
        )
  }

  if (match(forRoles)) {
    return children
  }
  return React.Children.map(children, (child) =>
    React.cloneElement(child, { style: { disabled: true, pointerEvents: 'none' } })
  )
}

export const HideTree = React.memo(_HideTree)
export const ShowTree = React.memo(_ShowTree)
export const DisableTree = React.memo(_DisableTree)
export const EnableTree = React.memo(_EnableTree)
