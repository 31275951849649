import styled from 'styled-components'
import { layout, space, color, typography, flexbox } from 'styled-system'

const TH = styled.th`
  padding: 0.7em;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  font-size: 12px;
  text-align: ${(props) => props.textAlign ?? 'unset'};
  ${layout};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
`
TH.defaultProps = {
  align: 'left',
}
export default TH
