import React from 'react'

const GateContext = React.createContext()

export function GateProvider({ user, children, off = false }) {
  const [_gateEnabled, _setEnabled] = React.useState(!off)

  const matchCache = React.useRef({})

  const shortCircuit = React.useCallback(
    (fn) => {
      return (...args) => {
        if (!_gateEnabled) {
          return true
        }
        return fn(...args)
      }
    },
    [_gateEnabled]
  )

  const roleMatches = React.useCallback(
    (roles = []) => {
      const cache = matchCache.current
      if (!roles.length && process.env.NODE_ENV !== 'production') {
        console.warn(
          `You are performing match against and empty collection of role, this might lead to incorrect behaviour`
        )
      }
      let key = roles.join('-')

      if (cache[key]) {
        return cache[key]
      }
      let match = roles.includes(user.user_role)
      matchCache.current[key] = match

      return match
    },
    [user]
  )

  const userHasRole = React.useCallback(
    (...roles) => {
      return roleMatches(roles.flat())
    },
    [roleMatches]
  )

  const userIsNot = React.useCallback(
    (...roles) => {
      return !roleMatches(roles.flat())
    },
    [roleMatches]
  )

  const value = React.useMemo(() => {
    return {
      roleMatches: shortCircuit(roleMatches),
      userHasRole: shortCircuit(userHasRole),
      userIsNot: shortCircuit(userIsNot),
      _setEnabled,
    }
  }, [roleMatches, shortCircuit, userHasRole, userIsNot])
  return (
    <GateContext.Provider value={value} key={_gateEnabled.valueOf()}>
      {children}
    </GateContext.Provider>
  )
}

export function useRoleMatch() {
  const { roleMatches } = React.useContext(GateContext)
  return roleMatches
}

export function useGate() {
  const { userIsNot, userHasRole } = React.useContext(GateContext)

  return { userIsNot, userHasRole }
}
