import styled from 'styled-components'
import { space, typography, color, flexbox, layout, position, grid, border, background, shadow } from 'styled-system'
const Box = styled.div`
  ${space};
  ${typography};
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${grid};
  ${border};
  ${background};
  ${shadow};
`
export default Box
