import { useState, useEffect } from 'react'
import { getLocation } from 'auth/api.client.js'

const useCoordsAndIP = () => {
  const [userIP, setUserIP] = useState(null)
  const [userLocation, setUserLocation] = useState(null)

  const getUserIP = () => {
    const response = getLocation()
    response.then((e) => {
      setUserIP({
        ip: e.data.ip,
      })
    })
  }

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setUserLocation({ latitude, longitude })
        },
        (error) => {
          console.error('Error getting user location:', error)
        }
      )
    } else {
      setUserLocation({ latitude: userIP.latitude, longitude: userIP.longitude })
    }
  }

  useEffect(() => {
    getUserIP()
    getUserLocation()
  }, [])

  return { userIP, userLocation }
}

export default useCoordsAndIP
