import React from 'react'
import { fetchTransactionStatuses } from 'transactions/transactions.api.client'

import { useQuery } from 'react-query'
import capitalize from 'lodash.capitalize'
import useCampaigns from 'lib/hooks/useCampaigns'
import { useTransactionTypes, useSearchByOptions, useTransactionUtils } from '../lib/App.Provider'

const TransactionConfigContext = React.createContext({
  statusValues: [],
  transactionTypes: [],
  searchByOptions: [],
})

function TransactionsConfigProvider({ children }) {
  const { data: statusValues = [] } = useQuery('transaction-config-statusValues', fetchTransactionStatuses, {
    staleTime: Infinity,
  })
  const searchByOptions = useSearchByOptions()
  const transactionTypes = useTransactionTypes()

  const value = React.useMemo(() => {
    return {
      statusValues,
      transactionTypes,
      searchByOptions,
    }
  }, [statusValues, transactionTypes, searchByOptions])

  return <TransactionConfigContext.Provider value={value}>{children}</TransactionConfigContext.Provider>
}

function useTransactionStatuses() {
  const { statusValues = [] } = React.useContext(TransactionConfigContext)
  return statusValues.map((status) => ({
    ...status,
    name: capitalize(status.name),
  }))
}

export {
  TransactionsConfigProvider,
  useTransactionStatuses,
  useCampaigns,
  useTransactionTypes,
  useSearchByOptions,
  useTransactionUtils,
}
