import React, { useState, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import styled from 'styled-components'

const getRoot = () => {
  let existingNode = document.getElementById('issue-reporter')
  if (existingNode) {
    return existingNode
  }
  let newNode = document.createElement('div')
  newNode.setAttribute('id', 'issue-reporter')
  document.body.appendChild(newNode)
  return newNode
}

const gdmOptions = {
  video: {
    cursor: 'always',
  },
  audio: false,
  // audio: {
  //   echoCancellation: true,
  //   noiseSuppression: true,
  //   sampleRate: 44100
  // }
}

const reporterStates = {
  IDLE: 'idle',
  READY: 'ready',
  ERROR: 'error',
  RECORDING: 'recording',
}

const options = { mimeType: 'video/webm; codecs=vp9' }

function IssueReporter() {
  const [status, setStatus] = useState(reporterStates.IDLE)
  const [mediaStream, setStream] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const recordingRef = useRef([])

  const getMediaStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(gdmOptions)
      setStream(stream)
      setStatus(reporterStates.READY)
    } catch (err) {
      setStatus(reporterStates.ERROR)
    }
  }, [])

  const download = useCallback(() => {
    var blob = new Blob(recordingRef.current, {
      type: 'video/webm',
    })
    var url = URL.createObjectURL(blob)
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = 'test.webm'
    a.click()
    window.URL.revokeObjectURL(url)
  }, [])

  const startRecording = useCallback(() => {
    const mediaRecorder = new MediaRecorder(mediaStream, options)

    function handleNewData(event) {
      if (event.data.size > 0) {
        recordingRef.current.push(event.data)
      } else {
        console.log(`No data`, event)
      }
    }

    function handleStop() {
      setStatus('recording-stoped')
      download()
    }

    mediaRecorder.ondataavailable = handleNewData
    mediaRecorder.onstop = handleStop
    setStatus(reporterStates.RECORDING)
    mediaRecorder.start()
    setRecorder(mediaRecorder)
  }, [mediaStream, download])
  if (true) {
    return null
  }

  return (
    <ReporterStyles px="xl" className={`is-${status}`}>
      {!mediaStream && (
        <Button mt="auto" mb="10px" variant="success" onClick={getMediaStream}>
          Report an issue
        </Button>
      )}
      {!!mediaStream && (
        <Box mx="auto" mt="auto" display="flex" justifyContent="space-between" alignItems="baseline" width="150px">
          {reporterStates.RECORDING !== status ? (
            <Button variant="error" className="recordButton" onClick={startRecording}>
              Record
            </Button>
          ) : (
            <>
              {recorder.state === 'recording' ? (
                <Button variant="info" className="stopButton" onClick={() => recorder.pause()}>
                  PAUSE
                </Button>
              ) : (
                <Button variant="blue" className="stopButton" onClick={() => recorder.pause()}>
                  RESUME
                </Button>
              )}
              <Button variant="error" className="stopButton" onClick={() => recorder.stop()}>
                STOP
              </Button>
            </>
          )}
        </Box>
      )}
    </ReporterStyles>
  )
}

/* @eslint-disable */
const ReporterStyles = styled(Box)`
  position: fixed;
  z-index: 9999;
  right: 5px;
  bottom: 3em;
  display: flex;
  transition: all 0.2s linear;
  pointer-events: none;

  &.is-ready {
    background: #82b6ff75;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    left: 0;
    bottom: 0;
    justify-content: flex-end;
    align-items: center;
  }

  .recordButton,
  .stopButton {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  &.is-recording {
    background: transparent;
  }
`
/* @eslint-enable */

export default function Reporter() {
  return createPortal(<IssueReporter />, getRoot())
}
