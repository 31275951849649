import styled from 'styled-components'
import { grid, flexbox, color, layout, space } from 'styled-system'

const Panel = styled.div`
  position: ${(props) => (props.shadow ? 'relative' : 'inherit')};
  z-index: ${(props) => (props.shadow ? 2 : 'inherit')};
  border: solid 1px ${(props) => props.theme.colors.lightgrey};
  border-radius: ${(props) => (props.curved ? '5px' : 0)};
  border-top-right-radius: ${(props) => (props.curvedTop || props.curved ? '5px' : 0)};
  border-top-left-radius: ${(props) => (props.curvedTop || props.curved ? '5px' : 0)};
  border-bottom-right-radius: ${(props) => (props.curvedBottom || props.curved ? '5px' : 0)};
  border-bottom-left-radius: ${(props) => (props.curvedBottom || props.curved ? '5px' : 0)};
  background-color: #ffffff;
  box-shadow: ${(props) => (props.shadow ? '0px 6px 6px 0px rgba(0,0,0,0.05);' : 'none')};
  ${layout};
  ${flexbox};
  ${grid};
  ${space};
  ${color};
`
export default Panel
