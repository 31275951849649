import { createGlobalStyle } from 'styled-components'
import Avenir from './assets/fonts/AvenirLTStd-Roman.woff'
import AvenirBold from './assets/fonts/AvenirLTStd-Heavy.woff'
import AvenirMedium from './assets/fonts/AvenirLTStd-Medium.woff'

const GlobalStyles = createGlobalStyle`
  @font-face {
  font-family: "Avenir";
  src: url(${Avenir}) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

  @font-face {
  font-family: "Avenir";
  src: url(${AvenirBold}) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
 }
  @font-face {
  font-family: "Avenir";
  src: url(${AvenirMedium}) format("woff");
  font-weight: bold;
  font-display: swap;
 }
html {
    box-sizing: border-box;
    font-family: "Avenir",serif;
    font-size: 16px;
    color: ${(props) => props.theme.colors.text};
    overflow-x: hidden;
}
  body {
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  *{
    box-sizing: border-box;
  }

  a,
  a:visited{
    color: inherit;
    text-decoration: none;
  }
  button{
    appearance: none;
    border: none;
    font-family: inherit;
    background: transparent;
    padding:0;
  }
  fieldset:disabled{
    appearance: none;
  }
  .dropbutton{
    text-align: left;
    border: none;
    appearance: none;
    outline: none;
    :hover,
    :focus{
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`
export default GlobalStyles
