import React, { useMemo } from 'react'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ReactQueryConfigProvider } from 'react-query'
import { useToast } from './toast/ToastContext'

export default function DevTool({ children }) {
  const { alert } = useToast()
  const queryConfig = useMemo(() => {
    return {
      onError: (err) => {
        alert(
          typeof err === 'string'
            ? err
            : 'There was a problem fetching data, please check your network connection or re authenticate!'
        )
      },
      retry: 2,
      staleTime: 10 * 60 * 1000,
    }
  }, [alert])
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </ReactQueryConfigProvider>
  )
}
