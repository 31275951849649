import React from 'react'

export function useToggle(initial = false) {
  const [value, setValue] = React.useState(initial)

  const toggleValue = React.useCallback((newValue) => {
    if (typeof newValue === 'boolean') {
      setValue(newValue)
    } else {
      setValue((v) => !v)
    }
  }, [])

  return [value, toggleValue]
}
