import React, { useCallback, useEffect, useState } from 'react'
import Button from 'design/elements/Button'
import TextField from 'design/elements/TextField'
import Text from 'design/elements/Text'
import Panel from 'design/elements/Panel'
import Spacer from 'design/elements/Spacer'
import { useForm } from 'react-hook-form'
import { requestPasswordReset, getLocation } from 'auth/api.client.js'
import { useMutation } from 'react-query'
import { loading } from 'lib/formatters'
import AuthShell from './AuthTemplate'
import { Link } from 'react-router-dom'
import Box from '../design/elements/Box'
import useCoordsAndIP from './LocationInfo'
import { useToggle } from 'lib/hooks/useToggle'
import Modal from 'design/elements/Modal'
import SucessfulPasswordReset from './SuceessfulPasswordResetModal'

export default function ForgotPassword() {
  return (
    <AuthShell>
      <Username />
    </AuthShell>
  )
}

function Username() {
  const { userIP, userLocation } = useCoordsAndIP()

  const { handleSubmit, register, watch } = useForm({ mode: 'onBlur' })

  const identifier = watch('identifier')
  const [mutate, { error, status }] = useMutation(requestPasswordReset)
  const [SucessfulResetModal, setSucessfulResetModal] = useToggle(false)

  const onSubmit = useCallback(
    (values) => {
      mutate(
        {
          ...values,
          otp: 'string',
          Ipaddress: userIP.ip,
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
        },
        {
          onSuccess: () => {
            setSucessfulResetModal()
          },
        }
      )
    },
    [mutate, userLocation, userIP, setSucessfulResetModal]
  )

  return (
    <Panel as="form" onSubmit={handleSubmit(onSubmit)} p={64} curved="4px">
      <Box textAlign="center" borderBottom="8px #F9F8FB solid" borderRadius="2px" px="30px">
        <Text fontSize="24px" as="h1" color="black" fontWeight="bold" mb="sm">
          Welcome to GateForce Portal
        </Text>
        <Text fontSize="16px" as="p" color="#667085" fontWeight="normal" lineHeight="20px" mb="xl">
          Please input your log in credentials to gain access to the dashboard
        </Text>
      </Box>

      <Spacer mt="xl" />

      <Box>
        <TextField
          error={status === 'error'}
          name="identifier"
          ref={register({
            required: true,
            pattern: {
              value: /[\w]{4,}/,
              message: 'Username must be 6 characters or more',
            },
          })}
          required
          placeholder="Username"
          label="Username"
          borderRadius="4px"
        />
        <Spacer mt="md" />
        {error && (
          <>
            <Text color="red" fontSize="x-small" textAlign="center">
              {error}
            </Text>
            <Spacer mt="xs" />
          </>
        )}

        <Button loading={loading(status)} fullWidth variant="dark" borderRadius="4px" disabled={!identifier}>
          Send
        </Button>

        <Box background="#E7EFF6" p="8px" mt="15px">
          <Text color="#667085" fontSize="14px" fontWeight="normal" lineHeight="20px">
            A one time password will be sent to the email address tied to the account username provided above.
          </Text>
        </Box>
      </Box>

      {SucessfulResetModal && (
        <Modal close={setSucessfulResetModal}>
          <SucessfulPasswordReset username={identifier} />
        </Modal>
      )}
      <Spacer mt="md" />
      <Text mt="xl" color="#667085" fontWeight="normal" textAlign="center" fontSize="14px">
        Back to login?
        <Text
          as="span"
          color="highlight"
          fontWeight="500"
          textAlign="center"
          fontSize="16px"
          borderBottom="1px solid #1C3244"
          ml="6px"
        >
          <Link to="/login">Click here</Link>
        </Text>
      </Text>
    </Panel>
  )
}

// function OTP({ username, next }) {
//   const { handleSubmit, register } = useForm({ mode: 'onBlur' })
//   const [mutate, { error, status }] = useMutation(validateOTP)
//   function onSubmit(values) {
//     mutate(Object.assign({}, values, { identifier: username }), {
//       onSuccess() {
//         next()
//       },
//     })
//   }
//   return (
//     <Panel as="form" onSubmit={handleSubmit(onSubmit)} p="xl" shadow>
//       <Text fontSize={20} as="h1" color="dark" fontWeight="bold" textAlign="center">
//         Enter OTP
//       </Text>
//       <Spacer mt="xl" />
//
//       <TextField
//         error={status === 'error'}
//         name="otp"
//         ref={register({
//           required: true,
//           pattern: {
//             value: /[\w]{4,}/,
//             message: 'OTP must be 4 characters or more',
//           },
//         })}
//         required
//         placeholder="OTP"
//       />
//       <Spacer mt="md" />
//       {error && (
//         <>
//           <Text color="red" fontSize="x-small" textAlign="center">
//             {error}
//           </Text>
//           <Spacer mt="xs" />
//         </>
//       )}
//
//       <Button loading={loading(status)} fullWidth variant="blue">
//         SUBMIT
//       </Button>
//       <Spacer mt="md" />
//
//       <Text mt="xl" color="dark" fontWeight="bold" textAlign="center" fontSize="sm">
//         <Link to="/login">Sign In</Link>
//       </Text>
//     </Panel>
//   )
// }

// function NewPassword() {
//   const { errors, handleSubmit, register } = useForm({ mode: 'onBlur' })
//   const [mutate, { error, status }] = useMutation(changePassword)
//   const { notify } = useToast()
//   function onSubmit(values) {
//     mutate(values, {
//       onSuccess(resp) {
//         notify(resp)
//       },
//     })
//   }
//   return (
//     <Panel as="form" onSubmit={handleSubmit(onSubmit)} p="xl" shadow>
//       <Text fontSize={20} as="h1" color="dark" fontWeight="bold" textAlign="center">
//         Change Password
//       </Text>
//       <Spacer mt="xl" />
//       <TextField
//         error={Boolean(errors.password)}
//         name="new_password"
//         helperText={Boolean(errors?.password?.message && 'Please provide a password')}
//         ref={register({ required: true })}
//         type="password"
//         required
//         placeholder="Password"
//       />
//
//       <Spacer mt="md" />
//       <TextField
//         error={Boolean(errors.confirm_password)}
//         name="confirm_password"
//         helperText={Boolean(errors?.confirm_password?.message && 'Confirm password')}
//         ref={register({ required: true })}
//         type="password"
//         required
//         placeholder="Confirm Password"
//       />
//       <Spacer mt="md" />
//       {error && (
//         <>
//           <Text color="red" fontSize="x-small" textAlign="center">
//             {error}
//           </Text>
//           <Spacer mt="xs" />
//         </>
//       )}
//
//       <Button loading={loading(status)} fullWidth variant="blue">
//         SUBMIT
//       </Button>
//       <Spacer mt="md" />
//
//       <Text mt="xl" color="dark" fontWeight="bold" textAlign="center" fontSize="sm">
//         <Link to="/login">Sign In</Link>
//       </Text>
//     </Panel>
//   )
// }
