import * as React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import Portal from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { ForceProvider } from 'design'
import Login from 'auth/Login'
import { AuthProvider, useAuthState, useLogin, USER_KEY, TOKEN_KEY, TOKEN_EXPIRY_KEY } from 'auth/context/Auth.Context'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'design/500'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ToastProvider from './toast/ToastContext'
import { APP_VERSION, environment, SENTRY_KEY } from 'lib/config'
import ForgotPassword from './auth/ForgotPassword'

const history = createBrowserHistory()
!environment.isDev &&
  Sentry.init({
    dsn: SENTRY_KEY,
    release: 'gateforce-portal@' + APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) }),
    ],
    tracesSampleRate: 1.0,
  })
function validatePreviousSession() {
  let tokenExpiryTime = sessionStorage.getItem(TOKEN_EXPIRY_KEY)
  if (Date.now() >= tokenExpiryTime) {
    sessionStorage.clear()
  }
}

function getProfileFromServer(token) {
  //get profile from server and return both <for now local>
  let user = JSON.parse(sessionStorage.getItem(USER_KEY))
  return Promise.resolve([user, token])
}

function bootstrap() {
  validatePreviousSession()
  let user = null
  let token

  token = JSON.parse(sessionStorage.getItem(TOKEN_KEY))

  if (!token) {
    return Promise.resolve([user, token])
  }

  if (process.env.NODE_ENV === 'production') {
    return getProfileFromServer(token)
  }

  user = JSON.parse(sessionStorage.getItem(USER_KEY))
  return Promise.resolve([user, token])
}

function App() {
  const { loggedIn } = useAuthState()
  const login = useLogin()
  return loggedIn ? (
    <Portal />
  ) : (
    <Switch>
      <Route path="/login">
        <Login onLogin={login} />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Redirect to="/login" />
    </Switch>
  )
}
bootstrap().then(([user, token]) => {
  ReactDOM.render(
    <React.StrictMode>
      <ForceProvider>
        <ToastProvider>
          <AuthProvider user={user} token={token}>
            <Router history={history}>
              <ErrorBoundary
                FallbackComponent={ErrorPage}
                onError={Sentry.captureException}
                onReset={() => {
                  window.location.pathname = '/'
                }}
              >
                <App />
              </ErrorBoundary>
            </Router>
          </AuthProvider>
        </ToastProvider>
      </ForceProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
