import styled, { keyframes } from 'styled-components'
import Box from 'design/elements/Box'

const buttonLoadAnimation = keyframes`
0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
`
export const ButtonLoader = styled.div`
  font-size: ${(props) => (props.small ? '2px' : '4px')};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: ${buttonLoadAnimation} 1.1s infinite ease;
  animation: ${buttonLoadAnimation} 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
`

const inputLoadAnimation = keyframes`
0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #1c3244, 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.5), -1.8em -1.8em 0 0em rgba(28,50,68, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.7), 1.8em -1.8em 0 0em #1c3244, 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.5), 1.8em -1.8em 0 0em rgba(28,50,68, 0.7), 2.5em 0em 0 0em #1c3244, 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.5), 2.5em 0em 0 0em rgba(28,50,68, 0.7), 1.75em 1.75em 0 0em #1c3244, 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.5), 1.75em 1.75em 0 0em rgba(28,50,68, 0.7), 0em 2.5em 0 0em #1c3244, -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.5), 0em 2.5em 0 0em rgba(28,50,68, 0.7), -1.8em 1.8em 0 0em #1c3244, -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.5), -1.8em 1.8em 0 0em rgba(28,50,68, 0.7), -2.6em 0em 0 0em #1c3244, -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.5), -2.6em 0em 0 0em rgba(28,50,68, 0.7), -1.8em -1.8em 0 0em #1c3244;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #1c3244, 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.5), -1.8em -1.8em 0 0em rgba(28,50,68, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.7), 1.8em -1.8em 0 0em #1c3244, 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.5), 1.8em -1.8em 0 0em rgba(28,50,68, 0.7), 2.5em 0em 0 0em #1c3244, 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.5), 2.5em 0em 0 0em rgba(28,50,68, 0.7), 1.75em 1.75em 0 0em #1c3244, 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.5), 1.75em 1.75em 0 0em rgba(28,50,68, 0.7), 0em 2.5em 0 0em #1c3244, -1.8em 1.8em 0 0em rgba(28,50,68, 0.2), -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.5), 0em 2.5em 0 0em rgba(28,50,68, 0.7), -1.8em 1.8em 0 0em #1c3244, -2.6em 0em 0 0em rgba(28,50,68, 0.2), -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.5), -1.8em 1.8em 0 0em rgba(28,50,68, 0.7), -2.6em 0em 0 0em #1c3244, -1.8em -1.8em 0 0em rgba(28,50,68, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(28,50,68, 0.2), 1.8em -1.8em 0 0em rgba(28,50,68, 0.2), 2.5em 0em 0 0em rgba(28,50,68, 0.2), 1.75em 1.75em 0 0em rgba(28,50,68, 0.2), 0em 2.5em 0 0em rgba(28,50,68, 0.2), -1.8em 1.8em 0 0em rgba(28,50,68, 0.5), -2.6em 0em 0 0em rgba(28,50,68, 0.7), -1.8em -1.8em 0 0em #1c3244;
  }
`
export const InputLoader = styled(Box)`
  font-size: ${(props) => props.size || (props.small ? 2 : 3)}px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: ${inputLoadAnimation} 1.1s infinite ease;
  animation: ${inputLoadAnimation} 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
`
