import React, { useMemo } from 'react'
import { Label } from './TextField'
import Chevron from 'design/assets/images/chevron.svg'
import Spacer from './Spacer'
import Box from './Box'
import styled from 'styled-components'
import Downshift from 'downshift'
import { InputLoader } from 'design/loaders'

const StyledSelect = styled(Box)`
  font-family: inherit;
  position: relative;
  width: 100%;

  .chevron {
    position: absolute;
    top: ${(props) => (props.small ? '20%' : '30%')};
    right: 1em;
  }

  .dropdownButton {
    position: relative;
    background: white;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: inherit;
    font-family: inherit;
    font-size: ${(props) => (props.small ? '10px' : '12px')};
    color: ${(props) => props.theme.colors.text};
    height: ${(props) => (props.small ? '25px' : props.height ? props.height : '40px')};
    border: solid 1px ${(props) => (props.invalid ? props.theme.colors.red : '#dbe0e5')};
    transition: inherit;

    :disabled {
      opacity: 0.3;
    }

    input, [data-selected] {
      width: 90%;
      border: none;
      box-shadow: none;
      outline: none;
      font-family: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      padding-right: 0.5em;
      color: #1c3244f2;
      font-size: ${(props) => (props.small ? '10px' : '12px')};

      ::placeholder {
        color: #8b9ba8;
        font-family: inherit;
      }
    }
  }

  span.form-helper-text {
    font-size: x-small;
    display: block;
    height: 20px;
    padding: 0.5em 0;
    transition: all 0.2s linear;
    position: absolute;
  }

  span.form-helper-text.error {
    color: ${(props) => props.theme.colors.red};
  }

  .dropdownMenu {
    position: absolute;
    z-index: 9999;
    max-width: 100%;
    max-height: 300px;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    opacity: 1;
    background: white;
    list-style: none;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.05);
    overflow-x: hidden;
    overflow-y: auto;

    :focus {
      outline: 1px solid currentColor;
    }
  }

  .dropdownItem {
    position: relative;
    z-index: inherit;
    cursor: pointer;
    font-size: 12px;
    margin: .5em 0;
    padding: .5em 0.5em;
    transition: all 0.1s linear;
    text-align: left;
    outline: none;
    box-shadow: none;
  }

  .dropdownItem.highlighted:not(.is-disabled) {
    color: white;
    background: ${(props) => props.theme.colors.highlight}
  }

  .dropdownItem.is-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

}`

function Select(props, ref) {
  const {
    invalid,
    name,
    loading,
    options = [],
    label,
    defaultValue = '',
    value = '',
    required,
    theme,
    disabled,
    onChange,
    onBlur,
    helperText,
    error,
    placeholder,
    small,
    labelField = 'name',
    valueField = 'value',
    form,
    height,
    ...styleProps
  } = props

  const itemToString = (item) => item?.[labelField]

  const _onChange = React.useCallback((...args) => onChange && onChange(...args), [onChange])
  const _options = useMemo(() => {
    if (options?.some((option) => option[valueField] === '')) return options
    return options.concat({ [labelField]: 'Select an option', [valueField]: '' })
  }, [options, valueField, labelField])

  const _value = React.useMemo(
    () =>
      _options.find((opt) => {
        return opt[valueField] === value || opt[valueField] === defaultValue
      }),
    [defaultValue, value, _options, valueField]
  )
  if (loading && onChange) {
    return (
      <StyledSelect invalid={error} key={label} small={small} height={height} forwardedAs="div" {...styleProps}>
        {label && (
          <>
            <Label small={small}>
              {label}
              {required && '*'}
            </Label>
            <Spacer mt="xs" />
          </>
        )}
        <button disabled={disabled} className="dropdownButton" type="button">
          <input disabled name={name} style={{ cursor: 'pointer' }} type="text" placeholder={placeholder} />
          <Box as="div" ml="auto" mr="5px" children={<InputLoader />} />
        </button>
      </StyledSelect>
    )
  }

  return (
    <Downshift disabled={disabled} selectedItem={_value} onChange={_onChange} itemToString={itemToString}>
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getRootProps,
        getToggleButtonProps,
      }) => (
        <StyledSelect
          {...getRootProps()}
          invalid={error}
          key={label}
          small={small}
          forwardedAs="div"
          isOpen={isOpen}
          height={height}
          {...styleProps}
        >
          {label && (
            <>
              <Label small={small} {...getLabelProps()}>
                {label}
                {required && '*'}
              </Label>
              <Spacer mt="xs" />
            </>
          )}
          <button
            {...getToggleButtonProps()}
            disabled={disabled}
            className={['dropdownButton', selectedItem ? 'hasValue' : ''].join(' ')}
            type="button"
          >
            <input
              name={name}
              disabled={disabled}
              style={{ cursor: 'pointer' }}
              type="text"
              placeholder={placeholder}
              {...getInputProps({ ref })}
              onBlur={onBlur}
            />
            {loading ? (
              <Box as="div" ml="auto" mr="5px" children={<InputLoader />} />
            ) : (
              <img src={Chevron} alt="" width={15} />
            )}
          </button>
          <ul className="dropdownMenu" {...getMenuProps()}>
            {isOpen &&
              _options.map((option, index) => {
                return (
                  <li
                    className={[
                      index === highlightedIndex ? 'highlighted' : undefined,
                      option.isDisabled ? 'is-disabled' : undefined,
                      'dropdownItem',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                    {...getItemProps({ item: option, index, disabled: option.isDisabled })}
                    key={`${option}${index}`}
                  >
                    {option[labelField]}
                  </li>
                )
              })}
          </ul>
          {helperText && (
            <span className={['form-helper-text', error && 'error'].filter(Boolean).join(' ')}>{helperText}</span>
          )}
        </StyledSelect>
      )}
    </Downshift>
  )
}

export default React.forwardRef(Select)
