export default function makeFormData(jsonData = {}) {
  if (typeof jsonData !== 'object') {
    throw new TypeError(`Invalid jsonData provided ${jsonData}`)
  }
  const formData = new FormData()
  for (let [key, value] of Object.entries(jsonData)) {
    if (value === null) {
      continue
    }
    switch (value?.constructor.name) {
      case 'File':
      case 'Blob':
        formData.append(key, value, value.name)
        continue

      case 'FileList':
        value.length > 0 && formData.append(key, value[0], value[0].name)
        continue

      default:
        formData.append(key, value)
    }
  }
  return formData
}
