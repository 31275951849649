import { useLocation } from 'react-router-dom'
import React from 'react'

export default function useQueryParams() {
  const params = React.useRef(new URLSearchParams(useLocation().search))
  let paramsObj = {}
  for (let [key, value] of params.current.entries()) {
    paramsObj[key] = value
  }
  return paramsObj
}
