import React from 'react'
import Box from 'design/elements/Box'
import Logo from 'design/assets/images/fmb-logo.svg'
import BackgroundImage from 'design/assets/images/login_background.svg'

export default function AuthShell({ children, ...props }) {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      style={{
        backgroundImage: ` url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        fontFamily: `'DM Sans', sans-serif`,
      }}
    >
      <Box width="541px" position="relative">
        <Box
          height={55}
          width={70}
          background="white"
          position="absolute"
          top={-10}
          zIndex={10}
          px="xs"
          right={225}
          borderRadius="3px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="GateForce Logo" style={{ maxWidth: '100%' }} />
        </Box>
        {React.cloneElement(children, props)}
      </Box>
    </Box>
  )
}
