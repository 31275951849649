import React from 'react'
import Button from 'design/elements/Button'
import Box from 'design/elements/Box'
import Panel from 'design/elements/Panel'
import Text from 'design/elements/Text'
import { Link } from 'react-router-dom'
import checkMark from 'design/assets/images/checked.svg'

const SucessfulPasswordReset = ({username}) => {
  return (
    <Panel p="xl" curved="4px" width="464px" display="flex" flexDirection="column" alignItems="center">
      <Box
        width="60px"
        height="60px"
        background="#23A26D1F"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb="xl"
      >
        <Box
          width="30px"
          height="30px"
          background="#23A26D"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {' '}
          <img src={checkMark} alt="checkMark" width={20}/>
        </Box>
      </Box>
      <Text fontSize="16px" as="p" color="#111111" fontWeight="bold" mb="lg">
        One time password
      </Text>
      <Text fontSize="14px" color="#667085" fontWeight="normal" mb="sm" lineHeight="20px">
        Your one time password has been successfully sent to the mail address tied to the username
        <span style={{ color: '#52BE95', marginLeft: '3px' }}>({username})</span> provided, go to your mail box to get
        password for login.
      </Text>
      <Box background="#FAE3E7" p="8px" mb="lg">
        <Text color="#D8314F" fontSize="14px" fontWeight="normal" lineHeight="20px">
          We advice to secure your account with a “New Password” soon after login with the one time password provided.
        </Text>
      </Box>

      <Button fullWidth variant="dark" borderRadius="4px">
        <Link to="/login">Continue to Login Page</Link>
      </Button>
    </Panel>
  )
}

export default SucessfulPasswordReset
