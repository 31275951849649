import React from 'react'
import Table from 'design/elements/Table'
import styled from 'styled-components'
import THead from 'design/elements/THead'
import TR from 'design/elements/TR'
import Box from './Box'
import TH from './TH'
import TD from './TD'
import Button from './Button'
import Text from './Text'
import Spacer from './Spacer'
import { usePopper } from 'react-popper'
import { InputLoader } from 'design/loaders'

export default function FullTable({
  elements,
  RenderRow,
  RenderHeaders,
  RenderMenu,
  pagination,
  loading,
  RenderNoData,
  onPageChange,
  getRowProps,
}) {
  if (onPageChange && typeof onPageChange !== 'function') {
    throw new TypeError(`onPageChange must be a function`)
  }
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'left'],
        },
      },
      {
        name: 'offset',

        options: {
          offset: [-80, 0],
        },
      },
    ],
  })

  const showMenu = React.useCallback((event) => {
    setReferenceElement(event.currentTarget)
  }, [])

  const closeMenu = React.useCallback(() => {
    setReferenceElement(null)
    setActiveRow(null)
  }, [setReferenceElement])

  const [activeRow, setActiveRow] = React.useState(null)

  const setAsMenuContextAndShowMenu = React.useCallback(
    (row) => (event) => {
      event.preventDefault()
      event.stopPropagation()
      setActiveRow(row)
      showMenu(event)
    },
    [setActiveRow, showMenu]
  )

  const page = pagination?.currentPage || 1
  const rowsPerPage = pagination?.perPage || 8
  const columns = RenderHeaders().props.children.length

  const [bodyHeight, setBodyHeight] = React.useState('20em')
  const bodyRef = React.useRef(null)
  const renderObjectFromApi = (row) => {
    const rowProps = Object.assign({}, getRowProps && { ...getRowProps(row) })
    return <RenderRow rowProps={rowProps} row={row} triggerMenu={setAsMenuContextAndShowMenu(row)} />
  }

  React.useLayoutEffect(() => {
    if (loading) return
    const { height } = bodyRef.current.getBoundingClientRect()
    setBodyHeight(height)
  }, [bodyRef, loading])

  return (
    <Wrapper>
      <Table>
        <THead borderTopColor="transparent">{RenderHeaders && <RenderHeaders />}</THead>
        <tbody ref={bodyRef}>
          {loading && (
            <TR>
              <TD colSpan={1000}>
                <Box height={bodyHeight} background="white" display="flex" justifyContent="center" alignItems="center">
                  <InputLoader size={7} />
                </Box>
              </TD>
            </TR>
          )}
          {RenderRow && !loading && elements?.length > 0 && Array.isArray(elements)
            ? elements.map((row, index) => {
                const rowProps = Object.assign({}, getRowProps && { ...getRowProps(row) })
                return (
                  <RenderRow rowProps={rowProps} index={index} row={row} key={index} triggerMenu={setAsMenuContextAndShowMenu(row)} />
                )
              })
            : typeof elements === 'object' && !Array.isArray(elements) && elements !== null
            ? renderObjectFromApi(elements)
            : !loading && (
                <TR>
                  <TD colSpan={1000}>
                    <Box
                      height={bodyHeight}
                      background="white"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {RenderNoData ? RenderNoData : 'There are no items to display'}
                    </Box>
                  </TD>
                </TR>
              )}
        </tbody>
        <THead as="tfoot">
          <TR>
            {Boolean(elements?.length) && pagination && (
              <TablePagination
                renderedCount={elements.length}
                count={pagination?.total || elements.length}
                rowsPerPage={rowsPerPage}
                colSpan={columns}
                page={page}
                onChangePage={onPageChange}
              />
            )}
          </TR>
        </THead>
      </Table>
      <div className={[activeRow ? 'show' : 'hide', 'menu'].join(' ')}>
        <button
          onClick={closeMenu}
          style={{
            transition: 'all 0.1s linear',
            backfaceVisibility: 'hidden',
            transform: 'translate3d(0,0,0)',
            outline: 'none',
            position: 'fixed',
            width: activeRow ? '100%' : '0px',
            zIndex: 90,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
        {!!activeRow &&
          RenderMenu &&
          RenderMenu(activeRow, closeMenu, () => ({
            ref: setPopperElement,
            style: { ...styles.popper, zIndex: 91 },
            ...attributes.popper,
          }))}
      </div>
    </Wrapper>
  )
}

function TablePagination({ colSpan, count, renderedCount, rowsPerPage, onChangePage, page }) {
  const totalPages = Math.ceil(count / rowsPerPage)
  const firstIndex = page * rowsPerPage - rowsPerPage + 1

  const handleBackButtonClick = () => {
    onChangePage && onChangePage(page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage && onChangePage(page + 1)
  }

  return (
    <Box as={TH} scope="col" colSpan={colSpan + 1}>
      <Box display="flex" justifyContent="center" alignItems="baseline">
        <Button
          onClick={() => onChangePage(1)}
          disabled={page <= 1}
          variant="default"
          aria-label="first page"
          title="first page"
        >
          &#8676;
        </Button>
        <Spacer ml="sm" />
        <Button onClick={handleBackButtonClick} disabled={page <= 1} variant="default" aria-label="previous page">
          &larr; Previous
        </Button>
        <Spacer ml="sm" />
        <Text fontSize="sm">
          <Text color="highlight" as="span">
            Page {page}
          </Text>
          &nbsp; | &nbsp;
          <Text as="small">
            {firstIndex} - {Math.min(firstIndex + renderedCount - 1, count)} of {count}
          </Text>
        </Text>

        <Spacer ml="sm" />
        <Button onClick={handleNextButtonClick} disabled={page === totalPages} variant="default" aria-label="next page">
          Next &rarr;
        </Button>
        <Spacer ml="sm" />
        <Button
          onClick={() => onChangePage(totalPages)}
          disabled={page === totalPages}
          variant="default"
          aria-label="last page"
          title="last page"
        >
          &#8677;
        </Button>
      </Box>
    </Box>
  )
}
const Wrapper = styled.div`
  .menu {
    transition: all 0.01s ease-in-out;
    transform-origin: 50% 50%;
    backface-visibility: hidden;
  }
  .hide {
    opacity: 0;
    height: 0;
  }
  .show {
    height: auto;
    opacity: 1;
  }
`
