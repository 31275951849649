import { fetchAgentCampaigns } from 'agents/agents.api.client'
import { useQuery } from 'react-query'
import { loading as isLoading } from 'lib/formatters'
import React from 'react'

export default function useCampaings() {
  const {
    data: _campaigns = [],
    status,
    error,
  } = useQuery('config-campaigns', fetchAgentCampaigns, {
    staleTime: Infinity,
  })
  const campaigns = React.useMemo(() => {
    let defaultCampaign = [{ name: 'All Campaigns', value: null }]
    if (!_campaigns?.length) return _campaigns
    return defaultCampaign.concat(_campaigns.map((c) => ({ name: c.title, value: c.id })))
  }, [_campaigns])
  return { campaigns, loading: isLoading(status), error }
}
