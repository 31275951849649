import styled from 'styled-components'
import { space, layout, grid, background } from 'styled-system'

const Divider = styled.div`
  background: #f5f8fa;
  margin-top: 1em;
  margin-bottom: 1em;
  ${space};
  ${grid};
  ${layout};
  ${background};
`

Divider.defaultProps = {
  height: '2px',
  width: '100%',
}
export default Divider
