import styled from 'styled-components'
import { layout, space, color, typography, flexbox, border } from 'styled-system'

const THead = styled.thead`
  border: solid 1px #e3ecf4;
  border-left-color: ${(props) => (props.noLeftBorder ? 'transparent' : '#e3ecf4')};
  border-right-color: ${(props) => (props.noRightBorder ? 'transparent' : '#e3ecf4')};
  border-top-color: ${(props) => (props.noTopBorder ? 'transparent' : '#e3ecf4')};
  border-bottom-color: ${(props) => (props.noBottomBorder ? 'transparent' : '#e3ecf4')};
  background-color: #f5f8fa;
  ${layout};
  ${space};
  ${color};
  ${typography};
  ${flexbox};
  ${border};
`
export default THead
