import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function Title({ children, appName = 'Payforce Admin' }) {
  return (
    <Helmet>
      <title>
        {Array.isArray(children) ? children.join('') : children} | {appName}{' '}
      </title>
    </Helmet>
  )
}
