import React, { useMemo, useState } from 'react'

import toQuery from 'lib/toQuery'
import useQueryParams from 'lib/hooks/useQueryParams'
import Box from 'design/elements/Box'
import InfluencersListTable from 'Marketing/tables/InfluencersListTable'
import { fetchAllInfluencers } from 'Marketing/marketing.api.client'
import { usePaginatedQuery } from 'react-query'
import { loading } from 'lib/formatters'
import Panel from 'design/elements/Panel'
import Text from 'design/elements/Text'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import FieldText from 'design/elements/FieldText'

export default function MarketingIndex() {
  const [loadCause, setLoadCause] = useState('INITIAL')
  const queryParams = useQueryParams()
  const [query, setQuery] = useState(queryParams)

  const [selectedInfluencer, setSelectedInfluencer] = useState(null)

  const fields = useMemo(() => {
    let base = [
      { field: 'id', label: 'Influencer ID' },
      {
        field: 'name',
        label: 'Name',
      },
      {
        field: 'promo_code',
        label: 'Promo Code',
      },
      {
        field: 'description',
        label: 'Description',
      },
    ]
    return base
  }, [selectedInfluencer])

  const {
    resolvedData: influencers,
    status,
    isFetching,
  } = usePaginatedQuery([`all-influencers`], () => fetchAllInfluencers(), {
    staleTime: 2 * 60 * 1000,
    notifyOnStatusChange: false,
    onSettled() {
      setLoadCause(null)
    },
  })

  return (
    <Box display="grid" gridTemplateColumns="1fr 25%" gridGap="1.5em" alignItems="flex-start">
      <Box>
        <Panel display="flex" alignItems="center" py="lg" px="xl">
          <Text textTransform="uppercase" as="h4" color="highlight" fontSize={12} fontWeight="bold">
            All Influencers
          </Text>
          {/* <Box width="75%" display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap="1em" ml="auto">
            <Box>
              <Button
                // onClick={refresh}
                fullWidth
                // disabled={isFetching}
                variant="blue"
                // loading={isFetching && ['REFRESH'].includes(loadCause)}
              >
                Refresh
              </Button>
            </Box>
            <Box>
              <Button
                fullWidth
                loader="Processing Export..."
                variant="default"
                // onClick={handleExport}
                // loading={['EXPORT'].includes(loadCause)}
                // disabled={['EXPORT'].includes(loadCause) || isFetching}
              >
                Export to Excel
              </Button>
            </Box>
          </Box> */}
        </Panel>
        <InfluencersListTable
          loading={loading(status)}
          influencers={influencers || []}
          selectedInfluencer={selectedInfluencer}
          setSelectedInfluencer={setSelectedInfluencer}
        />
      </Box>
      <Box>
        <Panel py="lg" px="xl">
          <Text textTransform="uppercase" as="h4" color="highlight" fontSize={12} fontWeight="bold">
            Influencer Details
          </Text>
        </Panel>
        {!!selectedInfluencer && (
          <Panel py="md">
            {fields.map((field, index) => (
              <Box key={index}>
                <Box px="xl">
                  <FieldText fontSize="x-small" isLabel>
                    {field.label}
                  </FieldText>
                  <FieldText mt="xs" isValue fontSize="x-small">
                    {field.transform
                      ? field.transform(selectedInfluencer[field.field])
                      : selectedInfluencer[field.field]}
                  </FieldText>
                </Box>
                {index < fields.length - 1 && <Divider />}
              </Box>
            ))}
          </Panel>
        )}
      </Box>
    </Box>
  )
}
