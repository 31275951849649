import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react'
import Toast from './Toast'
import { v4 as idGen } from 'uuid'

const ToastContext = createContext()

const DEFAULT_MESSAGES = {
  Error: 'Error!',
  Success: 'Success!',
  Warning: 'Warning!',
  Info: 'Info!',
}
const ACTIONS = {
  CLEAR_MESSAGES: 'clear_messages',
  REMOVE_MESSAGE: 'remove_message',
  ADD_MESSAGE: 'add_message',
}
const defaultState = {
  messages: [],
}

const stateReducer = function (state, action) {
  switch (action.type) {
    case ACTIONS.CLEAR_MESSAGES:
      return defaultState
    case ACTIONS.REMOVE_MESSAGE:
      return { ...state, messages: state.messages.filter((m) => m.id !== action.payload.id) }
    case ACTIONS.ADD_MESSAGE: {
      if (state.messages.some((m) => m.message === action.payload.message)) {
        return state
      }
      return { ...state, messages: [...state.messages, action.payload] }
    }
    default:
      throw new Error(`Unknown action ${action.type}`)
  }
}

function extractMessageAndTitle(_dispatcher, config) {
  return function (arg, _title) {
    let message, title
    if (typeof arg === 'object' && arg !== null) {
      message = arg.message
      title = arg.title
    } else {
      message = arg
      title = _title
    }
    const id = idGen()
    _dispatcher({
      type: config.action,
      payload: {
        title: title || config.defaultTitle,
        message: message || config.defaultMessage,
        id,
        type: config.type,
      },
    })
    setTimeout(() => _dispatcher({ type: ACTIONS.REMOVE_MESSAGE, payload: { id } }), 10_000)
  }
}

export default function ToastProvider({ children }) {
  const [{ messages }, dispatch] = useReducer(stateReducer, defaultState)

  const notify = useCallback(
    (...args) => {
      return extractMessageAndTitle(dispatch, {
        action: ACTIONS.ADD_MESSAGE,
        type: 'success',
        defaultMessage: DEFAULT_MESSAGES.Success,
        defaultTitle: 'Your operation was successful',
      })(...args)
    },
    [dispatch]
  )
  const alert = useCallback(
    (...args) => {
      return extractMessageAndTitle(dispatch, {
        action: ACTIONS.ADD_MESSAGE,
        type: 'error',
        defaultMessage: DEFAULT_MESSAGES.Error,
        defaultTitle: 'An error occurred while processing your request',
      })(...args)
    },
    [dispatch]
  )
  const warn = useCallback(
    (...args) => {
      return extractMessageAndTitle(dispatch, {
        action: ACTIONS.ADD_MESSAGE,
        type: 'warning',
        defaultMessage: DEFAULT_MESSAGES.Warning,
        defaultTitle: 'Warning',
      })(...args)
    },
    [dispatch]
  )
  const inform = useCallback(
    (...args) => {
      return extractMessageAndTitle(dispatch, {
        action: ACTIONS.ADD_MESSAGE,
        type: 'info',
        defaultMessage: DEFAULT_MESSAGES.Info,
      })(...args)
    },
    [dispatch]
  )
  const removeMessage = useCallback(
    (id) => {
      dispatch({ type: ACTIONS.REMOVE_MESSAGE, payload: { id } })
    },
    [dispatch]
  )
  const clearAlert = useCallback(() => {
    dispatch({ type: ACTIONS.CLEAR_MESSAGES })
  }, [dispatch])

  const value = useMemo(
    () => ({
      notify,
      alert,
      warn,
      inform,
      removeMessage,
      clearAlert,
    }),
    [notify, alert, warn, inform, removeMessage, clearAlert]
  )

  return (
    <ToastContext.Provider value={value}>
      <Toast messages={messages} remove={removeMessage} />
      {children}
    </ToastContext.Provider>
  )
}

export function useToast() {
  return useContext(ToastContext)
}
