import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { color, space } from 'styled-system'

const TabLink = styled(NavLink)`
  border: solid 1px #e3ecf4;
  background-color: #ffffff;
  cursor: pointer;
  appearance: none;
  display: inline-flex;
  padding: 1em 2em;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.small ? '9px' : '12px')};
  line-height: 1.33;
  min-width: ${(props) => (props.fullWidth ? '100%' : 'initial')};
  font-family: inherit;
  height: ${(props) => (props.small ? '25px' : '40px')};
  transition: all 0.2s linear;
  ${color};
  ${space};

  &.active {
    background-color: ${(props) => props.theme.colors.highlight};
    border-color: ${(props) => props.theme.colors.highlight};
    color: white;
    box-shadow: 0 20px 20px 0 #0f375a29;
  }
`

export default TabLink
