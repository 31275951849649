import { TOKEN_KEY } from 'auth/context/Auth.Context'
import { baseUrl } from 'lib/config'
import qs from 'lib/queryString'

export function getToken() {
  return JSON.parse(sessionStorage.getItem(TOKEN_KEY))
}

export function logout() {
  sessionStorage.clear()
  window.location.pathname = '/'
}

export function makeClient(_baseUrl = '', _defaultHeaders, getAuth, handlers) {
  async function client(url, body, config = {}) {
    const { headers, onSuccess, onResponse, onError, ...userConfig } = config

    const defaultHeaders = Object.assign(
      {},
      _defaultHeaders,
      { Accept: 'application/json' },
      body && body.constructor.name !== 'FormData' && { 'Content-Type': 'application/json' }
    )
    const _config = Object.assign(
      {},
      { method: 'POST', mode: 'cors', headers: Object.assign({}, defaultHeaders, headers, getAuth && getAuth()) },
      body && { body: body.constructor.name === 'FormData' ? trimValues(body) : JSON.stringify(trimValues(body)) },
      userConfig
    )
    const res = await fetch(`${_baseUrl}/${url}${_config.query ? qs(_config.query) : ''}`, _config)
    try {
      if (
        !res.ok &&
        res.status === 401 &&
        !['login', 'reset-password', 'transactions', 'pushnotifications'].some((s) => url.includes(s))
      ) {
        return logout()
      }

      let _body
      if (onResponse) {
        return onResponse ? onResponse(res) : handlers?.onResponse && handlers.onResponse(res)
      }
      if (res.ok && res.status === 200) {
        _body = await res.json()
        return onSuccess ? onSuccess(_body) : !!handlers?.onSuccess ? handlers.onSuccess(_body) : _body.result
      } else if (res.status === 204) {
        return onSuccess ? onSuccess([]) : !!handlers?.onSuccess ? handlers.onSuccess([]) : null
      } else {
        _body = await res.json()
        return onError
          ? onError(_body, res.status)
          : !!handlers?.onError
          ? handlers.onError(_body, res.status)
          : Promise.reject(_body?.error_message)
      }
    } catch (e) {
      return Promise.reject('Unexpected Server Error')
    }
  }

  client.get = (url, query, config) => client(url, null, { method: 'GET', query, ...config })
  client.put = (url, body, config = {}) => client(url, body, { method: 'PUT', ...config })
  client.patch = (url, body, config) => client(url, body, { method: 'PATCH', body: JSON.stringify(body), ...config })
  client.post = (url, body, config) => client(url, body, { method: 'POST', body: JSON.stringify(body), ...config })
  client.delete = (url, body, config) => client(url, body, { method: 'DELETE', ...config })

  return client
}

const trim = (value) => (typeof value === 'string' ? value.trim() : value)

const trimValues = (obj) => {
  if (obj.constructor.name === 'FormData') {
    for (let [key, value] of obj.entries()) {
      obj.set(key, trim(value))
    }
    return obj
  } else {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = trim(value)
      return acc
    }, {})
  }
}

export default makeClient(
  baseUrl,
  {
    'App-Id': process.env.REACT_APP_APP_ID,
  },
  () => ({
    Authorization: ['Bearer', getToken()].join(' '),
  })
)
