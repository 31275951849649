import React from 'react'
import { fetchBillerCommTypes } from 'billers/billers.api.client'
import { useQuery } from 'react-query'
import { capitalize } from 'lib/formatters'

const BillersContext = React.createContext({ commTypes: [] })

function BillersProvider({ children }) {
  const { data: _commTypes = [] } = useQuery('biller-config', fetchBillerCommTypes, {
    staleTime: Infinity,
  })
  const commTypes = React.useMemo(() => {
    return _commTypes.map((c) => ({ ...c, name: c.name?.split('_').map(capitalize).join(' ') }))
  }, [_commTypes])

  const value = React.useMemo(() => ({ commTypes }), [commTypes])

  return <BillersContext.Provider value={value}>{children}</BillersContext.Provider>
}

function useBillerCommissionTypes() {
  const { commTypes } = React.useContext(BillersContext)
  return commTypes
}
function useMatchBillerType(fieldToUse) {
  const types = useBillerCommissionTypes()
  return function matchTypes(value, field) {
    if (!types.length) {
      return null
    }
    let type = types.find((t) => t.value == value)

    if (type && fieldToUse) {
      return type[fieldToUse]
    }
    if (type && field) {
      return type[field]
    }
    return type
  }
}
export { BillersProvider, useBillerCommissionTypes, useMatchBillerType }
