import client from 'lib/client'

export const fetchAllBillers = (query) => client.get(`t/biller/RetrieveBillers`, query)
export const fetchBillerDetails = (query) => client.get(`t/biller/BillerDetails`, query)
export const fetchBillerItems = (query) => client.get(`t/biller/GetAllBillerItemsForBiller`, query)
export const fetchBillerCategories = () => client.get(`t/biller/BillersCategory`)
export const fetchBillerCommTypes = () => client.get(`t/biller/BillerCommissionTypeConfig`)
export const updateBiller = (body) => client(`t/biller/UpdateBiller`, body, { method: 'PATCH' })
export const activateBillerItem = (body) =>
  client(`t/biller/UpdateBillerItemActiveStatus/${body.BillerItemId}`, body, { method: 'PUT' })
export const deActivateBillerItem = (body) =>
  client(`t/biller/UpdateBillerItemActiveStatus/${body.BillerItemId}`, body, { method: 'PUT' })
