import curryN from 'ramda/es/curryN'

export function enumToOptions(_enum) {
  return _enum.map((name, value) => ({ name, value }))
}
export function objectEnumToOptions(objectEnum) {
  return Object.entries(objectEnum).map(([key, value]) => ({ name: value, value: key }))
}
export function toObjectsKeys(theEnum) {
  return Object.entries(theEnum).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {})
}
export const arrayConfigToOptions = (config) => config.map((option, index) => ({ name: option, value: index }))
export const fromEnum = curryN(2, function fromEnum(theEnum, value) {
  return theEnum[value]
})
