import React from 'react'

const USER_KEY = '__GF_USER'
const TOKEN_KEY = '__GF_TOKEN'
const TOKEN_EXPIRY_KEY = '__GF_TOKEN_EXPIRY_MS'
const TOKEN_EXPIRY_THRESHOLD = 1000 * 60 * 60 * 20

const AuthStateContext = React.createContext({
  __user: {},
  __token: null,
  __loggedIn: false,
  lastSelectedFieldStaffId: '',
})

const AuthActionContext = React.createContext()

const defaultAuthState = { __user: null, __token: null, __loggedIn: false, lastSelectedFieldStaffId: '' }
function authReducer(state, action) {
  switch (state.__loggedIn) {
    case false:
      switch (action.type) {
        case 'LOGIN':
          return {
            ...state,
            __user: Object.assign({}, action.payload.user),
            __token: action.payload.token,
            __loggedIn: true,
          }

        default:
          return state
      }

    case true:
      switch (action.type) {
        case 'LOGOUT':
          return { ...state, defaultAuthState }

        case 'SELECT_FIELD_STAFF':
          return {
            ...state,
            lastSelectedFieldStaffId: action.id,
          }
        default:
          return state
      }
    default:
      return state
  }
}

function AuthProvider({ user, token, children }) {
  const [authState, dispatch] = React.useReducer(
    authReducer,
    Object.assign({}, defaultAuthState, {
      __user: Object.assign({}, user),
      __token: token,
      __loggedIn: Boolean(token),
    })
  )

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthActionContext.Provider value={dispatch}>{children}</AuthActionContext.Provider>
    </AuthStateContext.Provider>
  )
}

function useAuthState() {
  const {
    __user: user,
    __token: token,
    __loggedIn: loggedIn,
    lastSelectedFieldStaffId,
  } = React.useContext(AuthStateContext)
  return { user, token, loggedIn, lastSelectedFieldStaffId }
}
function useLogin() {
  const dispatch = React.useContext(AuthActionContext)
  return React.useCallback(
    (user, token) => {
      dispatch({ type: 'LOGIN', payload: { user, token } })
    },
    [dispatch]
  )
}
function useLogout() {
  const dispatch = React.useContext(AuthActionContext)

  return React.useCallback(() => {
    dispatch({ type: 'LOGOUT' })
  }, [dispatch])
}
function useSelectFieldStaff() {
  const dispatch = React.useContext(AuthActionContext)

  return React.useCallback(
    (id) => {
      dispatch({ type: 'SELECT_FIELD_STAFF', id })
    },
    [dispatch]
  )
}

export {
  AuthProvider,
  useAuthState,
  useLogout,
  useLogin,
  useSelectFieldStaff,
  USER_KEY,
  TOKEN_KEY,
  TOKEN_EXPIRY_KEY,
  TOKEN_EXPIRY_THRESHOLD,
}
