export default function toQueryString(obj) {
  let query = Object.entries(obj)
    .filter(([_, value]) => {
      return Boolean(value) || Number.isFinite(value)
    })
    .map(([key, value]) =>
      value || Number.isFinite(value)
        ? `${encodeURIComponent(key)}=${typeof value === 'boolean' ? +value : encodeURIComponent(trim(value))}`
        : ''
    )
    .join(`&`)
  return query ? `?${query}` : ''
}

const trim = (value) => (typeof value === 'string' ? value.trim() : value)
