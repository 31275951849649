import React from 'react'
import styled from 'styled-components'

const HamburgerStyles = styled.div`
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  span.ham {
    width: 30px;
    height: 2px;
    background-color: white;
    position: relative;
  }

  span.ham:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 30px;
    bottom: 8px;
    background-color: white;
  }

  span.ham:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 20px;
    top: 8px;
    background-color: white;
  }
`
export default function Hamburger(props) {
  return (
    <HamburgerStyles>
      <span className="ham" {...props} />
    </HamburgerStyles>
  )
}
